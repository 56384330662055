import axios from 'axios';

import { Vehicle } from '../../../../data/vehicle';

type ResponseType = {
  id: string;
  name: string;
  model: string;
  status: string;
  ble: {
    uuid: string;
    pass: string;
  };
  batteryLevel: number;
  chagingStatus: string;
  movingStatus: string;
  lockStatus: string;
  serviceStatus: string;
  currentStation: string;
  geoLocation: { latitude: number; longitude: number } | null;
  locationSource: string;
  serviceStartTime?: string;
  geofenceStatus: string;
  lastUpdatedTime: string;
};

export interface UpdateVehicleProps {
  name: string;
  registeredBy?: string;
}

export const update = async (
  id: string,
  props: UpdateVehicleProps
): Promise<Vehicle> => {
  try {
    const response = await axios.patch<ResponseType>(`/vehicles/${id}`, {
      name: props.name,
      registeredBy: props.registeredBy
    });

    return new Vehicle(
      response.data.id,
      response.data.name,
      response.data.model,
      response.data.ble,
      response.data.batteryLevel,
      response.data.chagingStatus,
      response.data.movingStatus,
      response.data.lockStatus,
      response.data.serviceStatus,
      response.data.currentStation,
      response.data.geofenceStatus,
      new Date(response.data.lastUpdatedTime),
      response.data.locationSource,
      response.data.geoLocation != null
        ? {
            lat: response.data.geoLocation.latitude,
            lng: response.data.geoLocation.longitude
          }
        : undefined,
      response.data.serviceStartTime
        ? new Date(response.data.serviceStartTime)
        : undefined
    );
  } catch (error: any) {
    throw new Error(error.message);
  }
};
