import { createContext, useContext } from 'react';

import { Trip } from '../../data/trip';

import * as rest from '../../lib/api/rest';
import { TripsState, initailTripsState } from './trips-state';

export interface ITripsContext extends TripsState {
  fetch: (
    id?: string,
    params?: rest.vehicle.trip.ListRequestParams
  ) => Promise<void>;
  fetchMore: (params?: rest.vehicle.trip.ListRequestParams) => Promise<void>;
  focus: (focusedTrip: Trip) => void;
}

const defaultValue: ITripsContext = {
  ...initailTripsState,
  fetch: async (): Promise<void> => {},
  fetchMore: async (): Promise<void> => {},
  focus: (): void => {}
};

export const TripsContext = createContext<ITripsContext>(defaultValue);

export const useTrips = (): ITripsContext => useContext(TripsContext);
