import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Auth0Provider } from './providers/Auth0Provider';
import { ApolloProvider } from './providers/ApolloProvider';
import { OperationAlertDialogProvider } from './components/organisms/OperationAlertDialog';

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider>
      <ApolloProvider>
        <OperationAlertDialogProvider>
          <App />
        </OperationAlertDialogProvider>
      </ApolloProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
