import { intervalToDuration } from 'date-fns';

export const getRentalDuration = (
  rentalStartTime: Date,
  currentDate: Date
): { days: number; minutes: number; hours: number } => {
  const duration = intervalToDuration({
    start: rentalStartTime,
    end: currentDate
  });

  return {
    days: duration.days || 0,
    minutes: duration.minutes || 0,
    hours: duration.hours || 0
  };
};
