import { createContext, useContext } from 'react';

import { VehiclesState, initailVehiclesState } from './vehicles-state';

export interface IVehiclesContext extends VehiclesState {
  fetch: () => Promise<void>;
  allocateVehicleToStation: (vehicleId: string, stationId: string) => any;
  rentVehicle: (vehicleId: string, stationId: string) => any;
  returnVehicle: (vehicleId: string, stationId: string) => any;
}

const defaultValue: IVehiclesContext = {
  ...initailVehiclesState,
  fetch: async (): Promise<void> => {},
  allocateVehicleToStation: async (): Promise<void> => {},
  rentVehicle: async (): Promise<void> => {},
  returnVehicle: async (): Promise<void> => {}
};

export const VehiclesContext = createContext<IVehiclesContext>(defaultValue);

export const useVehicles = (): IVehiclesContext => useContext(VehiclesContext);
