import axios from 'axios';

import { Trip } from '../../../../../data/trip';

export interface ListRequestParams {
  cursor?: string;
  limit?: number;
}

type ResponseType = {
  histories: Array<{
    vehicleID: string;
    vehicleName: string;
    start: {
      stationID: string;
      stationName: string;
      odometer: number;
      startTime: string;
    };
    end: {
      stationID: string;
      stationName: string;
      odometer: number;
      endTime: string;
    } | null;
    path: Array<{
      latitude: number;
      longitude: number;
    }>;
  }>;
  nextCursor: string;
};

export const list = async (
  id?: string,
  params?: ListRequestParams
): Promise<{ metadata: any; trips: Array<Trip> }> => {
  const tripsURL = id ? `/vehicles/${id}/trips` : `/trips`;
  const response = await axios.get<ResponseType>(tripsURL, {
    params: {
      cursor: params?.cursor,
      limit: params?.limit
    }
  });

  const metadata = { nextCursor: response.data.nextCursor };

  const trips = response.data.histories.map(
    (history) =>
      new Trip(
        history.vehicleID,
        history.vehicleName,
        {
          stationID: history.start.stationID,
          stationName: history.start.stationName,
          odometer: history.start.odometer,
          startTime: new Date(history.start.startTime)
        },
        history.path,
        history.end != null
          ? {
              stationID: history.end.stationID,
              stationName: history.end.stationName,
              odometer: history.end.odometer,
              endTime: new Date(history.end.endTime)
            }
          : undefined
      )
  );

  return { metadata, trips };
};
