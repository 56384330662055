import React from 'react';
import { Box, CircularProgress, useTheme } from '@mui/material';

export function LoadingPage() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: theme.palette.grey[700],
        height: 'calc(var(--vh, 1vh) * 100)',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress
        size="12rem"
        thickness={2}
        sx={{ color: theme.palette.primary.light }}
      />
    </Box>
  );
}
