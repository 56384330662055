import axios from 'axios';

export interface SendCommandProps {
  lock: string;
  horn: string;
}

export const send = async (
  id: string,
  props: SendCommandProps
): Promise<void> => {
  try {
    await axios.post(`/vehicles/${id}/commands`, {
      command: { lock: props.lock, horn: props.horn }
    });
  } catch (error: any) {
    throw new Error(error);
  }
};
