import { Paper, LinearProgress } from '@mui/material';
import { GoogleMap, Marker, Circle } from '@react-google-maps/api';

import { useFacility } from '../../contexts/facility';
import { mapOptions } from '../../assets/mapOptions';

export function FacilityMap() {
  const { isLoading, facility } = useFacility();

  return (
    <Paper elevation={1}>
      {isLoading || !facility ? (
        <LinearProgress color="secondary" />
      ) : (
        <>
          {facility.geofence === undefined ? (
            <></>
          ) : (
            <GoogleMap
              zoom={facility.geofence.config.radius <= 400 ? 17 : 16}
              mapContainerClassName={'facility-map-container'}
              center={facility.geofence.config.centerPoint}
              clickableIcons={false}
              options={mapOptions}
            >
              <Marker position={facility.geofence.config.centerPoint} />
              <Circle
                center={facility.geofence.config.centerPoint}
                radius={facility.geofence.config.radius}
              />
            </GoogleMap>
          )}
        </>
      )}
    </Paper>
  );
}
