import axios from 'axios';

import { Facility, ICircularGeofence } from '../../../../data/facility';

export interface UpdateFacilityProps {
  name: string;
  position?: {
    lat: string;
    lng: string;
  };
  description?: string;
  address?: {
    countryOrRegion?: string;
    province?: string;
    city?: string;
    address1?: string;
    address2?: string;
    zipCode?: string;
  };
  timeZoneID?: string;
  businessHours?: {
    startTime?: string;
    endTime?: string;
  };
  geofence?: ICircularGeofence | null;
  registeredBy?: string;
}

type ResponseType = {
  id: string;
  name: string;
  geoLocation: { latitude: number; longitude: number };
  address: {
    countryOrRegion: string;
    province: string;
    city: string;
    address1: string;
    address2?: string;
    zipCode: string;
  };
  timeZoneID: string;
  businessHours: {
    startTime: string;
    endTime: string;
  };
  geofence: {
    type: string;
    config: {
      centerPoint: { latitude: number; longitude: number };
      radius: number;
    };
  } | null;
  description?: string;
  createdTime: string;
  createdBy: string;
  lastUpdatedTime: string;
  updatedBy: string;
};

export const update = async (
  id: string,
  props: UpdateFacilityProps
): Promise<Facility> => {
  try {
    const response = await axios.patch<ResponseType>(`/facilities/${id}`, {
      id,
      name: props.name,
      description: props.description,
      geoLocation: props.position && {
        latitude: props.position.lat,
        longitude: props.position.lng
      },
      address: props.address
        ? {
            ...props.address,
            address2:
              props.address.address2 === undefined ||
              props.address.address2 === null
                ? null
                : props.address.address2
          }
        : undefined,
      timeZoneID: props.timeZoneID,
      businessHours: props.businessHours,
      geofence:
        typeof props.geofence === 'undefined'
          ? undefined
          : props.geofence === null
          ? null
          : {
              ...props.geofence,
              config: {
                ...props.geofence?.config,
                centerPoint: {
                  latitude: props.geofence?.config.centerPoint.lat,
                  longitude: props.geofence?.config.centerPoint.lng
                }
              }
            },
      registeredBy: props.registeredBy
    });

    return new Facility(
      response.data.id,
      response.data.name,
      {
        lat: response.data.geoLocation.latitude,
        lng: response.data.geoLocation.longitude
      },
      response.data.address,
      response.data.timeZoneID,
      response.data.businessHours,
      new Date(response.data.createdTime),
      response.data.createdBy,
      new Date(response.data.lastUpdatedTime),
      response.data.updatedBy,
      response.data.geofence != null
        ? {
            ...response.data.geofence,
            config: {
              ...response.data.geofence.config,
              centerPoint: {
                lat: response.data.geofence.config.centerPoint.latitude,
                lng: response.data.geofence.config.centerPoint.longitude
              }
            }
          }
        : undefined,
      response.data.description
    );
  } catch (error: any) {
    throw new Error(error.messaage);
  }
};
