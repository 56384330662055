import React, { ReactNode, useEffect, useReducer } from 'react';

import { Station } from '../../data/station';
import { Vehicle } from '../../data/vehicle';

import * as rest from '../../lib/api/rest';
import { StationsContext } from './stations-context';
import { StationsState, initailStationsState } from './stations-state';

type Action =
  | {
      type: 'FETCH';
    }
  | {
      type: 'FETCHED';
      payload: {
        stations?: Array<Station>;
      };
    }
  | {
      type: 'FOCUS';
      payload: {
        focused?: {
          station: Station;
          vehicles?: Array<Vehicle>;
        };
      };
    }
  | {
      type: 'FETCH_VEHICLES';
    }
  | {
      type: 'FETCHED_VEHICLES';
      payload: {
        vehicles: Array<Vehicle>;
      };
    }
  | {
      type: 'CREATE';
      payload: {
        station: Station;
      };
    }
  | {
      type: 'UPDATE';
      payload: {
        station: Station;
      };
    }
  | {
      type: 'DISCARD';
      payload: {
        id: string;
      };
    }
  | {
      type: 'ERROR';
      payload: {
        error: Error;
      };
    };

function reducer(state: StationsState, action: Action): StationsState {
  switch (action.type) {
    case 'FETCH':
      return {
        ...state,
        isLoading: true
      };
    case 'FETCHED':
      return {
        ...state,
        isLoading: false,
        stations: action.payload.stations
      };
    case 'FOCUS':
      return {
        ...state,
        focused: action.payload.focused
      };
    case 'FETCH_VEHICLES':
      return {
        ...state,
        isLoadingVehicles: true
      };
    case 'FETCHED_VEHICLES':
      return {
        ...state,
        isLoadingVehicles: false,
        focused: state.focused && {
          ...state.focused,
          vehicles: action.payload.vehicles
        }
      };
    case 'CREATE':
      return {
        ...state,
        focused: {
          station: action.payload.station
        },
        stations: state.stations
          ? [action.payload.station, ...state.stations]
          : [action.payload.station]
      };
    case 'UPDATE':
      return {
        ...state,
        focused: {
          station: action.payload.station
        },
        stations: state.stations!.map((station) => {
          if (station.id === action.payload.station.id) {
            return action.payload.station;
          }
          return station;
        })
      };
    case 'DISCARD':
      return {
        ...state,
        stations: state.stations?.filter(
          (station) => station.id !== action.payload.id
        ),
        focused: undefined
      };
    case 'ERROR':
      return {
        ...state,
        error: action.payload.error
      };
  }
}

interface StationsProviderProps {
  children: ReactNode;
}

export function StationsProvider(props: StationsProviderProps) {
  const [state, dispatch] = useReducer(reducer, initailStationsState);

  useEffect(() => {
    (async () => {
      dispatch({ type: 'FETCH' });
      try {
        const { stations } = await rest.station.list();
        dispatch({ type: 'FETCHED', payload: { stations } });
      } catch (error) {
        dispatch({ type: 'ERROR', payload: { error: error as Error } });
      }
    })();
  }, []);

  const focus = async (focusedStation?: Station): Promise<void> => {
    dispatch({ type: 'FOCUS', payload: { focused: undefined } });
    if (typeof focusedStation === 'undefined') {
      return;
    }
    dispatch({
      type: 'FOCUS',
      payload: { focused: { station: focusedStation, vehicles: undefined } }
    });
  };

  const fetchVehicles = async (station: Station): Promise<void> => {
    dispatch({ type: 'FETCH_VEHICLES' });
    try {
      const response = await rest.vehicle.list({ limit: 20 });
      const vehicles = response.vehicles.filter(
        (vehicle) => vehicle.currentStation === station.id
      );
      dispatch({ type: 'FETCHED_VEHICLES', payload: { vehicles } });
    } catch (error) {
      dispatch({ type: 'ERROR', payload: { error: error as Error } });
    }
  };

  const create = async (
    props: rest.station.CreateStationProps
  ): Promise<Station | undefined> => {
    try {
      const station = await rest.station.create(props);
      dispatch({ type: 'CREATE', payload: { station } });
      return station;
    } catch (error) {
      dispatch({ type: 'ERROR', payload: { error: error as Error } });
    }
  };

  const update = async (
    id: string,
    props: rest.station.UpdateStationProps
  ): Promise<Station | undefined> => {
    try {
      const station = await rest.station.update(id, props);
      dispatch({ type: 'UPDATE', payload: { station } });
      return station;
    } catch (error) {
      dispatch({ type: 'ERROR', payload: { error: error as Error } });
    }
  };

  const discard = async (id: string): Promise<void> => {
    try {
      await rest.station.discard(id);
      dispatch({ type: 'DISCARD', payload: { id } });
    } catch (error) {
      dispatch({ type: 'ERROR', payload: { error: error as Error } });
    }
  };

  return (
    <StationsContext.Provider
      value={{
        ...state,
        focus,
        fetchVehicles,
        create,
        update,
        discard
      }}
    >
      {props.children}
    </StationsContext.Provider>
  );
}
