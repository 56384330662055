import { createContext, useContext } from 'react';

import { FacilityState, initailFacilityState } from './facility-state';
import { UpdateFacilityProps } from '../../lib/api/rest/facility/update';

export interface IFacilityContext extends FacilityState {
  update: (id: string, props: UpdateFacilityProps) => Promise<void>;
}

const defaultValue: IFacilityContext = {
  ...initailFacilityState,
  update: async (): Promise<void> => {}
};

export const FacilityContext = createContext<IFacilityContext>(defaultValue);

export const useFacility = (): IFacilityContext => useContext(FacilityContext);
