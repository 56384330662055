import { createContext, useContext } from 'react';

export enum DialogType {
  DISCARD,
  DEFAULT
}

interface ContextValue {
  isOpen: boolean;
  title?: string;
  description?: string;
  targets?: Array<string>;
  dialogType?: DialogType;
  errorMessage: string;
  loading: boolean;
  open: (...props: any) => void;
  agree: (...props: any) => void;
  close: (...props: any) => void;
  showLoading: (...props: any) => void;
}

const defaultValue: ContextValue = {
  errorMessage: '',
  loading: false,
  isOpen: false,
  open: () => {},
  agree: () => {},
  close: () => {},
  showLoading: () => {}
};

export const OperationAlertDialogContext =
  createContext<ContextValue>(defaultValue);

export const useOperationAlertDialog = (): ContextValue =>
  useContext(OperationAlertDialogContext);
