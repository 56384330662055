import React, { useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

import { Link, useLocation, useNavigate } from 'react-router-dom';

import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';

import Logout from '@mui/icons-material/Logout';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LanguageIcon from '@mui/icons-material/Language';
import logo from '../../assets/images/logo-blue.png';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.primary.main,
    boxSizing: 'border-box'
  }
}));

export function Header() {
  const { t, i18n } = useTranslation();
  const { user, logout } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tabValue, setTabValue] = useState(0);
  const [checked, setChecked] = useState<boolean>(i18n.language === 'ja');
  const open = Boolean(anchorEl);

  useEffect(() => {
    switch (location.pathname) {
      case '/':
      case location.pathname.startsWith('/vehicle') && location.pathname:
        setTabValue(0);
        break;
      case '/facility':
        setTabValue(1);
        break;
      case '/stations':
        setTabValue(2);
        break;
      case '/history':
        setTabValue(3);
        break;
    }
  }, []);

  const onClickProfile = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onClickLogout = () => {
    logout({
      returnTo:
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port && ':' + window.location.port)
    });
  };

  const onChangeLanguage = () => {
    if (i18n.language === 'ja') {
      i18n.changeLanguage('en');
    } else if (i18n.language === 'en') {
      i18n.changeLanguage('ja');
    }
    setChecked(!checked);
  };

  const headerMenus: Array<{
    label: string;
    path: string;
  }> = [
    { label: t('global.dashboard'), path: '/' },
    { label: t('global.facility'), path: '/facility' },
    { label: t('global.stations'), path: '/stations' },
    // { label: t('global.vehicles'), path: '/vehicles'},
    { label: t('global.histories'), path: '/history' }
  ];

  return (
    <AppBar
      position="static"
      sx={{ height: theme.mixins.toolbar.minHeight, backgroundColor: '#fff' }}
    >
      <Toolbar>
        <Box sx={{ display: { xs: 'flex' }, marginX: '1rem' }}>
          <Link to={'/'}>
            <img src={logo} alt="Logo" height="70" width="84" />
          </Link>
        </Box>
        <Box
          sx={{ flexGrow: 1, display: { xs: 'flex' }, ...theme.mixins.toolbar }}
        >
          <Tabs
            value={tabValue}
            sx={{ ...theme.mixins.toolbar }}
            TabIndicatorProps={{ sx: { height: 3 } }}
          >
            {headerMenus.map(({ label, path }) => (
              <Tab
                label={label}
                sx={{
                  color: '#1d1d1d',
                  textTransform: 'none',
                  fontSize: '1rem',
                  paddingTop: '1rem',
                  ...theme.mixins.toolbar
                }}
                onClick={() => {
                  navigate(path);
                }}
                key={label}
              />
            ))}
          </Tabs>
        </Box>
        {/* <Box>
          <Link to="/help">
            <IconButton sx={{ marginRight: '0.5rem' }}>
              <HelpOutlineIcon sx={{ fontSize: '1.7rem', color: 'white' }} />
            </IconButton>
          </Link>
        </Box> */}
        <LanguageIcon sx={{ fontSize: '1.5rem' }} color="secondary" />
        <Stack
          direction="row"
          spacing={0.75}
          alignItems="center"
          sx={{ marginLeft: '0.5rem', marginRight: '2rem' }}
        >
          <Typography color="#1d1d1d">EN</Typography>
          <AntSwitch checked={checked} onChange={onChangeLanguage} />
          <Typography color="#1d1d1d">JA</Typography>
        </Stack>
        <Box
          sx={{ display: { xs: 'flex' }, alignItems: { xs: 'center' } }}
          onClick={onClickProfile}
        >
          <Avatar
            src={user?.picture}
            sx={{ marginRight: '3rem', width: 43, height: 43 }}
          />
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={onClose}
          PaperProps={{ sx: { width: 150 } }}
        >
          <MenuItem onClick={onClickLogout}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            {t('global.logout')}
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
