import { ReactNode, useEffect } from 'react';

import { Box } from '@mui/material';

import { Header } from '../organisms';
import { StyleTemplate } from '../StyleTemplate';

interface TemplateProps {
  children?: ReactNode;
}

export function Template(props: TemplateProps) {
  const setFillHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  window.addEventListener('resize', setFillHeight);

  useEffect(() => {
    setFillHeight();
  }, []);

  return (
    <StyleTemplate>
      <link
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
        rel="stylesheet"
      />
      <Header />
      <Box sx={{ marginTop: '2px' }}>{props.children}</Box>
    </StyleTemplate>
  );
}
