import React, { ReactNode, useEffect, useReducer } from 'react';

import { Facility } from '../../data/facility';
import { useAuth0 } from '@auth0/auth0-react';

import * as rest from '../../lib/api/rest';
import { FacilityContext } from './facility-context';
import { FacilityState, initailFacilityState } from './facility-state';

type Action =
  | {
      type: 'FETCH';
    }
  | {
      type: 'FETCHED';
      payload: {
        facility?: Facility;
      };
    }
  | {
      type: 'UPDATED';
      payload: {
        facility: Facility;
      };
    }
  | {
      type: 'ERROR';
      payload: {
        error: Error;
      };
    };

function reducer(state: FacilityState, action: Action): FacilityState {
  switch (action.type) {
    case 'FETCH':
      return {
        ...state,
        isLoading: true
      };
    case 'FETCHED':
      return {
        ...state,
        isLoading: false,
        facility: action.payload.facility
      };
    case 'UPDATED':
      return {
        ...state,
        facility: action.payload.facility
      };
    case 'ERROR':
      return {
        ...state,
        error: action.payload.error
      };
  }
}

interface FacilityProviderProps {
  children: ReactNode;
}

export function FacilityProvider(props: FacilityProviderProps) {
  const [state, dispach] = useReducer(reducer, initailFacilityState);

  const { user } = useAuth0();
  const facilityID: string =
    user?.['https://whill-share.com/md/app_metadata'].facility_id;

  const update = async (
    id: string,
    props: rest.facility.UpdateFacilityProps
  ): Promise<void> => {
    try {
      const facility = await rest.facility.update(id, props);
      dispach({ type: 'UPDATED', payload: { facility } });
    } catch (error) {
      dispach({ type: 'ERROR', payload: { error: error as Error } });
    }
  };

  useEffect(() => {
    (async () => {
      dispach({ type: 'FETCH' });
      try {
        const facility = await rest.facility.get(facilityID);
        dispach({ type: 'FETCHED', payload: { facility } });
      } catch (error) {
        dispach({ type: 'ERROR', payload: { error: error as Error } });
      }
    })();
  }, []);

  return (
    <FacilityContext.Provider value={{ ...state, update }}>
      {props.children}
    </FacilityContext.Provider>
  );
}
