import React, { useState, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Divider,
  Grid
} from '@mui/material';
import { CircularProgress } from '@mui/material';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Vehicle } from '../../../data/vehicle';
import { Station } from '../../../data/station';
import stationIcon from '../../../assets/images/station-icon.svg';

import { get as getVehicle } from '../../../lib/api/rest/vehicle/get';

import { mapOptions } from '../../../assets/mapOptions';

export enum DialogType {
  SETUP,
  RETURN
}
interface SelectStationDialogProps {
  type: DialogType;
  isOpen: boolean;
  stations: Array<Station>;
  vehicle: Vehicle;
  dialogClose: (reload?: boolean) => void;
  allocateVehicleToStation: (
    vehicleId: string,
    selectedStationId: string
  ) => void;
  returnVehicle: (vehicleId: string, selectedStationId: string) => void;
}

export function SelectStationDialog(
  props: SelectStationDialogProps
): ReactElement {
  const {
    isOpen,
    dialogClose,
    vehicle,
    type,
    stations,
    allocateVehicleToStation,
    returnVehicle
  } = props;

  const initialStation: Station | undefined = stations[0];

  const { t } = useTranslation();
  const [selectedStation, setSelectedStation] = useState<Station | undefined>(
    initialStation
  );
  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState(false);

  const [loadedMarker, setLoadedMarker] = useState<
    google.maps.Marker | undefined
  >();
  const [loadedWindow, setLoadedWindow] = useState<
    google.maps.InfoWindow | undefined
  >();
  const [map, setMap] = useState<google.maps.Map>();

  const onClickMarkerHandler = (station: Station): void => {
    if (loadedWindow) {
      return;
    }
    const window = new google.maps.InfoWindow({
      content: `<div><div class="station-map-info-line" ></div><div class="station-map-info-window">${station.name}</div></div>`
    });
    setLoadedWindow(window);
    window.open(map, loadedMarker);
    window.addListener('closeclick', () => {
      setLoadedWindow(undefined);
    });
  };

  const onLoadMarkerHandler = (
    marker: google.maps.Marker,
    station: Station
  ): void => {
    setLoadedMarker(marker);
    const window = new google.maps.InfoWindow({
      content: `<div><div class="station-map-info-line" ></div><div class="station-map-info-window">${station.name}</div></div>`,
      disableAutoPan: true
    });
    setLoadedWindow(window);
    window.open(map, marker);
    window.addListener('closeclick', () => {
      setLoadedWindow(undefined);
    });
  };

  const onLoadMapHandler = (loadedMap: google.maps.Map) => {
    setMap(loadedMap);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const station = stations.find((station) => station.id === e.target.value);
    setSelectedStation(station);
  };

  const onClickUpdate = async () => {
    if (!selectedStation) {
      return;
    }
    setErrorMessage(undefined);
    try {
      setLoading(true);
      const { serviceStatus } = await getVehicle(vehicle.id);
      if (serviceStatus !== vehicle.serviceStatus) {
        throw new Error('Service Status Is Not Latest');
      }

      if (type === DialogType.SETUP) {
        await allocateVehicleToStation(vehicle.id, selectedStation.id);
      } else if (type === DialogType.RETURN) {
        await returnVehicle(vehicle.id, selectedStation.id);
      }
      dialogClose(true);
    } catch (e) {
      if ((e as Error).message === 'Service Status Is Not Latest') {
        setErrorMessage(t('dialog.serviceStatusNotLatest.error'));
        return;
      }
      if (type === DialogType.SETUP) {
        setErrorMessage(t('select.station.dailog.allocation.error'));
      } else if (type === DialogType.RETURN) {
        setErrorMessage(t('select.station.dailog.return.error'));
      }
    } finally {
      setLoading(false);
    }
  };

  const onClickCancel = () => {
    dialogClose();
  };

  return (
    <Dialog
      open={isOpen}
      PaperProps={{ className: 'select-station-dialog-container' }}
    >
      <DialogTitle className={'dialog-title'}>
        {type === DialogType.SETUP
          ? t('vehicle.dialog.setup.title')
          : type === DialogType.RETURN && t('vehicle.dialog.return.title')}
      </DialogTitle>
      <Divider className={'dialog-alert-divider'} />
      {stations.length >= 1 && selectedStation ? (
        <>
          <DialogContent
            className={'dialog-content-alert'}
            style={{ margin: '-24px -24px' }}
          >
            <Grid container>
              <Grid xs={6} item>
                <RadioGroup
                  className={'dialog-radio-button'}
                  defaultValue={selectedStation.id}
                  onChange={handleChange}
                >
                  {stations?.map((station) => {
                    return (
                      <FormControlLabel
                        value={station.id}
                        control={<Radio />}
                        label={station.name}
                      />
                    );
                  })}
                  {<Typography color="error">{errorMessage}</Typography>}
                </RadioGroup>
              </Grid>
              <Grid xs={6} item>
                <GoogleMap
                  zoom={17}
                  mapContainerClassName={'select-station-map-container'}
                  center={selectedStation.position}
                  onLoad={(loadedMap) => {
                    onLoadMapHandler(loadedMap);
                  }}
                  options={mapOptions}
                >
                  {selectedStation && (
                    <Marker
                      key={selectedStation.id}
                      title={selectedStation.name}
                      position={selectedStation.position}
                      icon={stationIcon}
                      onClick={(event) => onClickMarkerHandler(selectedStation)}
                      onLoad={(marker) =>
                        onLoadMarkerHandler(marker, selectedStation)
                      }
                    />
                  )}
                </GoogleMap>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider className={'dialog-alert-divider'} />
          <DialogActions className={'dialog-action-alert'}>
            <Button
              onClick={onClickCancel}
              disabled={loading}
              className={
                loading
                  ? 'dialog-cancel-button-disabled'
                  : 'dialog-cancel-button'
              }
            >
              {t('button.cancel')}
            </Button>
            <Button
              className={
                loading ? 'dialog-ok-button-disabled' : 'dialog-ok-button'
              }
              disabled={loading}
              startIcon={
                loading ? (
                  <CircularProgress
                    size={20}
                    className={'dialog-ok-button-loading'}
                    color={'success'}
                  />
                ) : undefined
              }
              onClick={onClickUpdate}
            >
              {t('vehicle.dialog.setup.submit.button')}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent>
            <Typography display="inline" color="error">
              {t('vehicle.dialog.noStations.pre')}
            </Typography>
            <Typography display="inline">
              <Link to="/stations">
                {t('vehicle.dialog.noStations.stationPage')}
              </Link>
            </Typography>
            <Typography display="inline" color="error">
              {t('vehicle.dialog.noStations.post')}
            </Typography>
          </DialogContent>
          <Divider className={'dialog-alert-divider'} />
          <DialogActions className={'dialog-action-alert'}>
            <Button onClick={onClickCancel} className={'dialog-cancel-button'}>
              {t('button.cancel')}
            </Button>
            <Button className={'dialog-ok-button-disabled'} disabled>
              {t('vehicle.dialog.setup.submit.button')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
