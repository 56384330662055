import { gql } from '@apollo/client';

export enum NotificationType {
  'CALL' = 'call',
  'LOW_BATTERY' = 'low-battery',
  'GEOFENCE' = 'geofence'
}

export interface ICallTarget {
  stationID: string;
  stationName: string;
}

export interface ILowBatteryTarget {
  vehicleID: string;
  vehicleName: string;
  batteryLevel: number;
}

export interface IGeofenceTarget {
  geofenceStatus: string;
  vehicleID: string;
  vehicleName: string;
}

export interface SubscriptionResponse {
  onPublishNotification: {
    facilityID: string;
    target: string;
    type: NotificationType;
    createdTime: string;
  };
}

export const onPublishNotification = gql`
  subscription onPublishNotification($facilityID: String!) {
    onPublishNotification(facilityID: $facilityID) {
      facilityID
      target
      type
      createdTime
    }
  }
`;
