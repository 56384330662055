import React from 'react';

import { VehicleProvider } from '../../contexts/vehicle';
import { Template } from '../templates/Template';

import { VehicleDetail, VehicleDetailMap } from '../organisms';
import PageLayout from '../organisms/PageLayout';

import { OperationAlertDialog } from '../organisms/OperationAlertDialog';

export function VehiclePage() {
  return (
    <Template>
      <VehicleProvider>
        <PageLayout
          childrenBar={<VehicleDetail />}
          childrenMap={<VehicleDetailMap />}
        />
        <OperationAlertDialog />
      </VehicleProvider>
    </Template>
  );
}
