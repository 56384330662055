import React, { ReactNode } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import {
  ApolloProvider as Provider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink
} from '@apollo/client';

interface ApolloProviderProps {
  children: ReactNode;
}

export function ApolloProvider(props: ApolloProviderProps) {
  const url = process.env.REACT_APP_APPSYNC_ENDPOINT_URL!;
  const region = process.env.REACT_APP_APPSYNC_REGION!;

  const { getAccessTokenSilently } = useAuth0();

  const httpLink = createHttpLink({ uri: url });

  const link = ApolloLink.from([
    createAuthLink({
      url,
      region,
      auth: { type: 'OPENID_CONNECT', jwtToken: getAccessTokenSilently }
    }),
    createSubscriptionHandshakeLink(
      {
        url,
        region,
        auth: { type: 'OPENID_CONNECT', jwtToken: getAccessTokenSilently }
      },
      httpLink
    )
  ]);

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache()
  });

  return <Provider client={client}>{props.children}</Provider>;
}
