import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useVehicle } from '../../contexts/vehicle';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import {
  Battery30,
  Battery50,
  Battery80,
  BatteryFull,
  Lock,
  QuestionMark,
  GpsFixed,
  GpsOff,
  ArrowBackIosNew,
  PlayCircleFilledOutlined,
  Stop
} from '@mui/icons-material';

import { format, isFuture } from 'date-fns';
import { getRentalDuration } from '../../lib/utils/getRentalDuraion';
import UnLock from '../../assets/images/unlock-icon.svg';

import {
  useOperationAlertDialog,
  DialogType as OperationAlertDialogType
} from '../organisms/OperationAlertDialog';
import { UpdateVehicleDialog } from '../organisms/VehicleDialog';
import { SelectStationDialog, DialogType } from './VehicleDialog';
import { Vehicle } from '../../data/vehicle';
import { SvgIcon } from '@mui/material';
import { ReactComponent as editIcon } from '../../assets/images/edit-icon.svg';
import { APIType } from '../../contexts/vehicle';

import { get as getVehicle } from '../../lib/api/rest/vehicle/get';

export function VehicleDetail() {
  const history = useNavigate();
  const { t } = useTranslation();
  const {
    vehicle,
    commandHistory,
    fetch,
    isLoading,
    stations,
    rentVehicle,
    allocateVehicleToStation,
    returnVehicle
  } = useVehicle();
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState<boolean>(false);
  const [openSelectStation, setOpenSelectStation] = useState(false);
  const [targetVehicle, setTargetVehicle] = useState<Vehicle>();
  const [type, setType] = useState<DialogType>(0);
  const [date, setDate] = useState(new Date());
  const { open, showLoading } = useOperationAlertDialog();

  useEffect(() => {
    fetch();
  }, []);

  const onClickEditHandler = (): void => {
    setIsOpenUpdateModal(true);
  };

  useEffect(() => {
    setInterval(() => setDate(new Date()), 10000);
  }, []);

  const onClickToggleHandler = () => {
    history('/');
  };
  const handleDialogClose = (reload?: boolean) => {
    setOpenSelectStation(false);
    setTargetVehicle(undefined);
    if (reload) fetch(APIType.vehicle);
  };

  const onClickRent = (targetVehicle: Vehicle) => {
    if (targetVehicle) {
      const onClickOk = async () => {
        try {
          showLoading(true);
          const { serviceStatus } = await getVehicle(targetVehicle.id);

          if (serviceStatus !== targetVehicle.serviceStatus) {
            throw new Error('Service Status Is Not Latest');
          }

          await rentVehicle(targetVehicle.id, targetVehicle.currentStation);
          fetch(APIType.vehicle);
        } catch (error) {
          if ((error as Error).message === 'Service Status Is Not Latest') {
            throw Error(t('dialog.serviceStatusNotLatest.error'));
          }
          throw Error(t('vehicle.dailog.rent.error'));
        } finally {
          showLoading(false);
        }
      };
      open(
        t('vehicle.dialog.rent.title'),
        `${t('vehicle.dialog.rent.content')}`,
        () => onClickOk,
        [targetVehicle.name],
        OperationAlertDialogType.DEFAULT
      );
    }
  };
  return (
    <Grid>
      {isLoading ? (
        <LinearProgress color="secondary" />
      ) : (
        vehicle && (
          <Paper elevation={1} className="vehicle-details-container">
            <Grid item container>
              <Button
                onClick={onClickToggleHandler}
                className={'vehicle-details-backbutton'}
                startIcon={<ArrowBackIosNew />}
              >
                {t('vehicle.details.backButton')}
              </Button>
            </Grid>
            <Grid
              direction="column"
              container
              className={'vehicle-details-container-body'}
            >
              <Grid
                item
                container
                className={'vehicle-details-header-container'}
              >
                <Grid item>
                  <Box className={'vehicle-details-header-title'}>
                    {vehicle.locationSource === 'gnss' ? (
                      <GpsFixed
                        color="success"
                        className="vehicle-list-gps-icon"
                      />
                    ) : (
                      <GpsOff
                        color="disabled"
                        className="vehicle-list-gps-icon"
                      />
                    )}
                    <Typography className={'vehicle-card-title'}>
                      {vehicle.name || 'unnamed'}
                    </Typography>
                    <Typography className={'vehicle-card-title-caption'}>
                      {`(${vehicle.model})`}
                    </Typography>
                  </Box>
                  {vehicle.serviceStatus === 'toBeSetup' && (
                    <Typography variant="caption" color="tomato">
                      {t('vehicle.serviceStatus.option.toBeSetup.message')}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  container
                  justifyContent={'space-between'}
                  paddingTop={'1rem'}
                >
                  <Grid
                    item
                    container
                    xs={10}
                    display={'flex'}
                    justifyContent={'flex-end'}
                  >
                    {vehicle.serviceStatus === 'toBeSetup' && (
                      <Button
                        onClick={() => {
                          setTargetVehicle(vehicle);
                          setOpenSelectStation(true);
                          setType(DialogType.SETUP);
                        }}
                        className={'vehicle-status-button'}
                        variant="contained"
                      >
                        {t('vehicle.card.status.setup')}
                      </Button>
                    )}
                    {vehicle.serviceStatus === 'isReady' && (
                      <Button
                        className={'vehicle-status-button'}
                        variant="contained"
                        onClick={() => onClickRent(vehicle)}
                        startIcon={<PlayCircleFilledOutlined />}
                      >
                        {t('vehicle.card.status.rent')}
                      </Button>
                    )}
                    {vehicle.serviceStatus === 'inService' && (
                      <Button
                        className={'vehicle-status-button'}
                        variant="contained"
                        onClick={() => {
                          setTargetVehicle(vehicle);
                          setOpenSelectStation(true);
                          setType(DialogType.RETURN);
                        }}
                        startIcon={<Stop />}
                      >
                        {t('vehicle.card.status.return')}
                      </Button>
                    )}
                  </Grid>
                  <Grid xs={2} item justifyContent={'end'}>
                    <SvgIcon
                      onClick={onClickEditHandler}
                      className={'vehicle-details-svg'}
                      component={editIcon}
                      inheritViewBox
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item>
                  <Typography
                    className="vehicle-subtitle-header"
                    component={'div'}
                  >
                    {t('vehicles.status')}
                  </Typography>
                </Grid>
                <Grid
                  xs={12}
                  item
                  container
                  className={'vehicle-card-header-container'}
                >
                  <Grid
                    item
                    xs={12}
                    display={'flex'}
                    justifyContent={'space-between'}
                    padding={'0.5rem 0 0.5rem 0'}
                  >
                    <Typography className={'vehicle-card-subheader'}>
                      {t('vehicle.connectionTime')}
                    </Typography>
                    <Typography className={'vehicle-card-subheader-value'}>
                      {format(vehicle.lastUpdatedTime, 'MM/dd HH:mm:ss')}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    display={'flex'}
                    justifyContent={'space-between'}
                    padding={'0 0 0.5rem 0'}
                  >
                    <Typography className={'vehicle-card-subheader'}>
                      {t('vehicle.serviceStatus.title')}
                    </Typography>
                    <Typography className={'vehicle-card-subheader-value'}>
                      {vehicle.serviceStatus === 'toBeSetup'
                        ? t('vehicle.serviceStatus.option.toBeSetup')
                        : vehicle.serviceStatus === 'inService'
                        ? t('vehicle.serviceStatus.option.inService')
                        : vehicle.serviceStatus === 'isReady'
                        ? t('vehicle.serviceStatus.option.isReady')
                        : vehicle.serviceStatus === 'notReady'
                        ? t('vehicle.serviceStatus.option.notReady')
                        : t('global.noData')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display={'flex'}
                    justifyContent={'space-between'}
                    padding={'0 0 0.5rem 0'}
                  >
                    <Typography className={'vehicle-card-subheader'}>
                      {t('vehicle.inService.duration')}
                    </Typography>
                    <Typography className={'vehicle-card-subheader-value'}>
                      {vehicle.serviceStatus !== 'inService'
                        ? '---'
                        : !vehicle.serviceStartTime
                        ? t('global.noData')
                        : isFuture(vehicle.serviceStartTime)
                        ? t('global.invalid')
                        : getRentalDuration(vehicle.serviceStartTime, date)
                            .days >= 1
                        ? t('vehicle.inService.duration.over24h')
                        : getRentalDuration(vehicle.serviceStartTime, date)
                            .hours +
                          'h ' +
                          getRentalDuration(vehicle.serviceStartTime, date)
                            .minutes +
                          'm'}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs={12} item container>
                  <Grid item xs={6} className={'vehicle-card-button-icons'}>
                    <Box className="vehicle-card-buttom-value">
                      <Box className="vehicle-card-icon">
                        {vehicle.lockStatus === 'locked' ? (
                          <Lock className={'vehicle-card-img'} />
                        ) : vehicle.lockStatus === 'unlocked' ? (
                          <img src={UnLock} alt="unlock-icon" />
                        ) : (
                          <QuestionMark className={'vehicle-card-img'} />
                        )}
                      </Box>
                      <Typography
                        className={
                          'vehicle-card-icon-value vehicle-card-subheader-value'
                        }
                      >
                        {vehicle.lockStatus === 'locked'
                          ? t('vehicle.lock.option.lock')
                          : vehicle.lockStatus === 'unlocked'
                          ? t('vehicle.lock.option.unlock')
                          : t('global.noData')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} className={'vehicle-card-button-icons'}>
                    <Box className="vehicle-card-buttom-value">
                      <Box className="vehicle-card-icon">
                        {0 < vehicle.batteryLevel &&
                        vehicle.batteryLevel <= 30 ? (
                          <Battery30 className={'vehicle-card-img'} />
                        ) : 30 < vehicle.batteryLevel &&
                          vehicle.batteryLevel <= 60 ? (
                          <Battery50 className={'vehicle-card-img'} />
                        ) : 60 < vehicle.batteryLevel &&
                          vehicle.batteryLevel <= 80 ? (
                          <Battery80 className={'vehicle-card-img'} />
                        ) : 80 < vehicle.batteryLevel ? (
                          <BatteryFull className={'vehicle-card-img'} />
                        ) : (
                          <QuestionMark className={'vehicle-card-img'} />
                        )}
                      </Box>
                      <Typography
                        className={
                          'vehicle-card-icon-value vehicle-card-subheader-value'
                        }
                      >
                        {vehicle.batteryLevel !== 0
                          ? vehicle.batteryLevel + '%'
                          : t('global.noData')}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        )
      )}
      {vehicle && (
        <UpdateVehicleDialog
          isOpen={isOpenUpdateModal}
          setIsOpen={setIsOpenUpdateModal}
          vehicle={vehicle}
        />
      )}
      {openSelectStation && targetVehicle && stations && (
        <SelectStationDialog
          type={type}
          stations={stations}
          isOpen={openSelectStation}
          vehicle={targetVehicle}
          dialogClose={handleDialogClose}
          allocateVehicleToStation={allocateVehicleToStation}
          returnVehicle={returnVehicle}
        />
      )}
    </Grid>
  );
}
