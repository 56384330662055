import React from 'react';

import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';

interface ErrorMessage {
  title: string;
  message: string;
}

export function ErrorDialog(error: ErrorMessage) {
  return (
    <Dialog open>
      <DialogTitle>
        <Typography variant="h5">{error.title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{error.message}</Typography>
      </DialogContent>
    </Dialog>
  );
}
