import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useStations } from '../../contexts/stations';

import {
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Paper,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { CreateStationDialog } from '../organisms/StationDialog';
import { Station } from '../../data/station';

interface StationsListProps {
  toggleClick: () => void;
}
export function StationsList(props: StationsListProps) {
  const { toggleClick } = props;
  const { t } = useTranslation();
  const { isLoading, stations, focused, focus, fetchVehicles } = useStations();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClickCreate = () => {
    setIsOpen(true);
  };

  const onClickCardHandler = async (station: Station) => {
    toggleClick();
    if (focused && station.id === focused.station.id) {
      return;
    }
    focus(station);
    fetchVehicles(station);
  };
  return (
    <Paper elevation={1} className={'station-container'}>
      <Grid container direction="column">
        {isLoading ? (
          <LinearProgress color="secondary" />
        ) : (
          <Grid
            item
            container
            direction={'column'}
            className={'station-list-panel'}
          >
            <Grid item className={'station-list-header'}>
              <Typography className={'station-list-text'} component="div">
                {t('global.stations.list')}
              </Typography>
              <Button
                variant="contained"
                size="small"
                className={'station-create-button'}
                onClick={onClickCreate}
                startIcon={<AddIcon fontSize="small" />}
              >
                {t('button.create')}
              </Button>
            </Grid>
            <Grid item container direction="column">
              {stations?.map((station) => (
                <Grid item key={station.id}>
                  <Card
                    className={'station-card'}
                    onClick={() => onClickCardHandler(station)}
                  >
                    <CardContent className={'station-card-content'}>
                      <Typography
                        className={'station-name-in-card'}
                        component="div"
                      >
                        {station.name}
                      </Typography>
                      <Grid xs display={'flex'}>
                        <Typography
                          className={'station-capacity-text'}
                          component="div"
                        >
                          {t('station.capacity')}
                        </Typography>
                        <Typography
                          marginLeft={'1rem'}
                          className={'station-capacity-value'}
                          component="div"
                        >
                          {station.capacity}
                        </Typography>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        <CreateStationDialog isOpen={isOpen} setIsOpen={setIsOpen} />
      </Grid>
    </Paper>
  );
}
