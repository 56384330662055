import React from 'react';

import { Template } from '../templates/Template';

import { FacilityProvider } from '../../contexts/facility';
import { FacilityDetail, FacilityMap } from '../organisms';
import PageLayout from '../organisms/PageLayout';

import '../../css/facility.css';
export function FacilityPage() {
  return (
    <Template>
      <FacilityProvider>
        <PageLayout
          childrenBar={<FacilityDetail />}
          childrenMap={<FacilityMap />}
        />
      </FacilityProvider>
    </Template>
  );
}
