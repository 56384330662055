import { useState } from 'react';

import { StationsProvider } from '../../contexts/stations';
import { FacilityProvider } from '../../contexts/facility';

import { Template } from '../templates/Template';

import { StationsList, StationDetail, StationsMap } from '../organisms';
import PageLayout from '../organisms/PageLayout';

import '../../css/station.css';
import { OperationAlertDialog } from '../organisms/OperationAlertDialog';

export function StationsPage() {
  const [toggle, setToggle] = useState<boolean>(false);
  const onToggleClick = () => {
    setToggle(!toggle);
  };
  return (
    <Template>
      <FacilityProvider>
        <StationsProvider>
          <PageLayout
            childrenBar={
              toggle ? (
                <StationDetail toggleClick={onToggleClick} />
              ) : (
                <StationsList toggleClick={onToggleClick} />
              )
            }
            childrenMap={<StationsMap />}
          />
          <OperationAlertDialog />
        </StationsProvider>
      </FacilityProvider>
    </Template>
  );
}
