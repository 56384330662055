import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Divider,
  Typography
} from '@mui/material';

import {
  useOperationAlertDialog,
  DialogType
} from './OperationAlertDialogContext';
import { CircularProgress } from '@mui/material';

export function OperationAlertDialog() {
  const {
    isOpen,
    title,
    description,
    targets,
    dialogType,
    agree,
    close,
    loading,
    errorMessage
  } = useOperationAlertDialog();
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      PaperProps={{
        className: 'dialog-alert-container'
      }}
    >
      <DialogTitle component={'div'} className={'dialog-title'}>
        {title}
      </DialogTitle>
      <Divider className={'dialog-alert-divider'} />
      <DialogContent className={'dialog-content-alert'}>
        <DialogContentText className={'dialog-content-description'}>
          {description}
        </DialogContentText>
        {targets && targets?.length !== 0 && (
          <DialogContentText>
            <ul className={'dialog-content-text'}>
              {targets.map((target) => (
                <>
                  <li className="dialog-content-li"> {target}</li>
                </>
              ))}
            </ul>
          </DialogContentText>
        )}
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </DialogContent>
      <DialogActions className={'dialog-action-alert'}>
        {dialogType === DialogType.DISCARD && (
          <>
            <Button onClick={close} className={'dialog-cancel-button'}>
              {t('button.cancel')}
            </Button>
            <Button className={'dialog-delete-button'} onClick={agree}>
              {t('button.delete')}
            </Button>
          </>
        )}
        {(dialogType === undefined || dialogType === DialogType.DEFAULT) && (
          <>
            <Button
              onClick={close}
              className={
                loading
                  ? 'dialog-cancel-button-disabled'
                  : 'dialog-cancel-button'
              }
              size="small"
              color="inherit"
              disabled={loading}
            >
              {t('button.cancel')}
            </Button>
            <Button
              onClick={agree}
              disabled={loading}
              className={
                loading ? 'dialog-ok-button-disabled' : 'dialog-ok-button'
              }
              size="small"
              variant="contained"
              startIcon={
                loading ? (
                  <CircularProgress
                    size={20}
                    className={'dialog-ok-button-loading'}
                    color={'success'}
                  />
                ) : undefined
              }
            >
              {t('button.apply')}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
