import axios from 'axios';

import { Vehicle } from '../../../../data/vehicle';

type ResponseType = {
  id: string;
  name: string;
  model: string;
  ble: {
    uuid: string;
    pass: string;
  };
  batteryLevel: number;
  chargingStatus: string;
  movingStatus: string;
  lockStatus: string;
  serviceStatus: string;
  currentStation: string;
  geoLocation: { latitude: number; longitude: number } | null;
  locationSource: string;
  serviceStartTime?: string;
  geofenceStatus: string;
  lastUpdatedTime: string;
};

export const get = async (id: string): Promise<Vehicle> => {
  const response = await axios.get<ResponseType>(`/vehicles/${id}`);

  return new Vehicle(
    response.data.id,
    response.data.name,
    response.data.model,
    response.data.ble,
    response.data.batteryLevel,
    response.data.chargingStatus,
    response.data.movingStatus,
    response.data.lockStatus,
    response.data.serviceStatus,
    response.data.currentStation,
    response.data.geofenceStatus,
    new Date(response.data.lastUpdatedTime),
    response.data.locationSource,
    response.data.geoLocation != null
      ? {
          lat: response.data.geoLocation.latitude,
          lng: response.data.geoLocation.longitude
        }
      : undefined,
    response.data.serviceStartTime
      ? new Date(response.data.serviceStartTime)
      : undefined
  );
};
