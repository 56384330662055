import React, { ReactNode } from 'react';

import {
  Auth0Provider as Provider,
  Auth0ProviderOptions
} from '@auth0/auth0-react';
import { AppState } from '@auth0/auth0-react/dist/auth0-provider';

const onRedirectCallback = (appState?: AppState): void => {
  window.history.replaceState(
    appState,
    '',
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const providerConfig: Auth0ProviderOptions = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN!,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
  audience: process.env.REACT_APP_AUTH0_SHARE_API_AUDIENCE!,
  redirectUri: window.location.origin,
  onRedirectCallback
};

interface Auth0ProviderProps {
  children: ReactNode;
}

export function Auth0Provider(props: Auth0ProviderProps) {
  return <Provider {...providerConfig}>{props.children}</Provider>;
}
