import React, { ReactNode, useEffect, useReducer } from 'react';

import { useSubscription } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';

import { Vehicle, IVehicleStatus } from '../../data/vehicle';
import { Station } from '../../data/station';
import * as rest from '../../lib/api/rest';
import * as graphql from '../../lib/api/graphql';
import { VehiclesContext } from './vehicles-context';
import { initailVehiclesState, VehiclesState } from './vehicles-state';

type Action =
  | {
      type: 'FETCH';
    }
  | {
      type: 'FETCHED';
      payload: {
        vehicles?: Array<Vehicle>;
        stations?: Array<Station>;
      };
    }
  | {
      type: 'UPDATE_PROFILE';
      payload: {
        vehicle: Vehicle;
      };
    }
  | {
      type: 'UPDATE_STATUS';
      payload: {
        vehicleId: string;
        vehicleStatus: IVehicleStatus;
      };
    }
  | {
      type: 'ERROR';
      payload: {
        error: Error;
      };
    };

function reducer(state: VehiclesState, action: Action): VehiclesState {
  switch (action.type) {
    case 'FETCH':
      return {
        ...state,
        isLoading: true
      };
    case 'FETCHED':
      return {
        ...state,
        isLoading: false,
        vehicles: action.payload.vehicles,
        stations: action.payload.stations ?? state.stations
      };
    case 'UPDATE_PROFILE':
      return {
        ...state,
        vehicles: state.vehicles!.map((vehicle) => {
          if (vehicle.id === action.payload.vehicle.id) {
            return action.payload.vehicle;
          }
          return vehicle;
        })
      };
    case 'UPDATE_STATUS':
      return {
        ...state,
        vehicles: state.vehicles?.map((vehicle) => {
          if (vehicle.id === action.payload.vehicleId) {
            return vehicle.updateStatus(action.payload.vehicleStatus);
          }
          return vehicle;
        })
      };
    case 'ERROR':
      return {
        ...state,
        error: action.payload.error
      };
  }
}

interface VehiclesProviderProps {
  children: ReactNode;
}

export function VehiclesProvider(props: VehiclesProviderProps) {
  const [state, dispatch] = useReducer(reducer, initailVehiclesState);
  const { user } = useAuth0();

  const facilityID: string =
    user?.['https://whill-share.com/md/app_metadata'].facility_id;

  const onSubscriptionVehicleStatus = async (
    data?: graphql.vehicle.SubscriptionResponse
  ): Promise<void> => {
    if (!data || state.isLoading) {
      return;
    }

    const vehicleStatus: IVehicleStatus = {
      batteryLevel: data.onPublishVehicleStatus.batteryLevel,
      chargingStatus: data.onPublishVehicleStatus.chargingStatus,
      movingStatus: data.onPublishVehicleStatus.movingStatus,
      lockStatus: data.onPublishVehicleStatus.lockStatus,
      serviceStatus: data.onPublishVehicleStatus.serviceStatus,
      currentStation: data.onPublishVehicleStatus.currentStationID,
      position: {
        lng: data.onPublishVehicleStatus.geoLocationLongitude,
        lat: data.onPublishVehicleStatus.geoLocationLatitude
      },
      geofenceStatus: data.onPublishVehicleStatus.geofenceStatus,
      serviceStartTime: data.onPublishVehicleStatus.serviceStartTime
        ? new Date(data.onPublishVehicleStatus.serviceStartTime)
        : undefined,
      locationSource: data.onPublishVehicleStatus.locationSource,
      lastUpdatedTime: new Date(data.onPublishVehicleStatus.createdTime)
    };
    dispatch({
      type: 'UPDATE_STATUS',
      payload: {
        vehicleId: data.onPublishVehicleStatus.vehicleID,
        vehicleStatus
      }
    });
  };

  useSubscription<graphql.vehicle.SubscriptionResponse>(
    graphql.vehicle.onPublishVehicleStatus,
    {
      variables: { facilityID },
      onSubscriptionData: (option) =>
        onSubscriptionVehicleStatus(option.subscriptionData.data)
    }
  );

  const fetch = async (): Promise<void> => {
    dispatch({ type: 'FETCH' });
    try {
      const { vehicles } = await rest.vehicle.list({
        limit: 20
      });
      dispatch({
        type: 'FETCHED',
        payload: {
          vehicles
        }
      });
    } catch (error) {
      dispatch({ type: 'ERROR', payload: { error: error as Error } });
    }
  };

  const allocateVehicleToStation = async (
    vehicleId: string,
    stationId: string
  ): Promise<void> => {
    await rest.vehicle.setup(vehicleId, stationId);
  };
  const rentVehicle = async (
    vehicleId: string,
    stationId: string
  ): Promise<void> => {
    await rest.vehicle.rent(vehicleId, stationId);
  };
  const returnVehicle = async (
    vehicleId: string,
    stationId: string
  ): Promise<void> => {
    await rest.vehicle.returnVehicle(vehicleId, stationId);
  };
  useEffect(() => {
    (async () => {
      dispatch({ type: 'FETCH' });
      try {
        const responses = await Promise.all([
          rest.vehicle.list({ limit: 20 }),
          rest.station.list()
        ]);
        dispatch({
          type: 'FETCHED',
          payload: {
            vehicles: responses[0].vehicles,
            stations: responses[1].stations
          }
        });
      } catch (error) {
        dispatch({ type: 'ERROR', payload: { error: error as Error } });
      }
    })();
  }, []);

  return (
    <VehiclesContext.Provider
      value={{
        ...state,
        fetch,
        allocateVehicleToStation,
        rentVehicle,
        returnVehicle
      }}
    >
      {props.children}
    </VehiclesContext.Provider>
  );
}
