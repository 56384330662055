import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useFacility } from '../../../contexts/facility';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  Divider
} from '@mui/material';

import { Facility } from '../../../data/facility';

interface UpdateFacilityDialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  focusedFacility: Facility;
}

export function UpdateFacilityDialog(props: UpdateFacilityDialogProps) {
  const { t } = useTranslation();
  const { update } = useFacility();

  const [countryOrRegion, setCountryOrRegion] = useState<string>(
    props.focusedFacility.address.countryOrRegion
  );
  const [zipCode, setZipCode] = useState<string>(
    props.focusedFacility.address.zipCode
  );
  const [province, setProvice] = useState<string>(
    props.focusedFacility.address.province
  );
  const [city, setCity] = useState<string>(props.focusedFacility.address.city);
  const [address1, setAddress1] = useState<string>(
    props.focusedFacility.address.address1
  );
  const [address2, setAddress2] = useState<string | undefined>(
    props.focusedFacility.address.address2
  );

  useEffect(() => {
    setCountryOrRegion(props.focusedFacility.address.countryOrRegion);
    setZipCode(props.focusedFacility.address.zipCode);
    setProvice(props.focusedFacility.address.province);
    setCity(props.focusedFacility.address.city);
    setAddress1(props.focusedFacility.address.address1);
    setAddress2(props.focusedFacility.address.address2);
  }, [props.isOpen]);

  const countryOrRegionChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCountryOrRegion(event.target.value);
  };

  const zipCodeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZipCode(event.target.value);
  };

  const provinceChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProvice(event.target.value);
  };

  const cityChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCity(event.target.value);
  };

  const address1ChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAddress1(event.target.value);
  };

  const address2ChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAddress2(event.target.value);
  };

  const onClickCancel = () => {
    props.setIsOpen(false);
  };

  const onClickUpdate = async () => {
    await update(props.focusedFacility.id, {
      name: props.focusedFacility.name,
      address: {
        countryOrRegion,
        zipCode,
        province,
        city,
        address1,
        address2
      }
    });
    props.setIsOpen(false);
  };

  return (
    <Dialog
      open={props.isOpen}
      PaperProps={{
        className: 'facility-edit-dialog-container'
      }}
    >
      <DialogTitle className={'facility-edit-title'} component={'div'}>
        {t('facility.dialog.update.title')}
      </DialogTitle>
      <Divider className={'facility-edit-divider'} />
      <DialogContent>
        <Typography className="dialog-uppercase-header">
          {t('facility.address')}
        </Typography>
        <TextField
          required
          autoFocus
          label={t('facility.address.countryOrRegion')}
          fullWidth
          margin="normal"
          defaultValue={countryOrRegion}
          InputLabelProps={{ shrink: true, sx: { fontWeight: 400 } }}
          onChange={countryOrRegionChangeHandler}
        />
        <TextField
          required
          label={t('facility.address.zipCode')}
          fullWidth
          margin="normal"
          defaultValue={zipCode}
          InputLabelProps={{ shrink: true, sx: { fontWeight: 400 } }}
          onChange={zipCodeChangeHandler}
        />
        <TextField
          required
          label={t('facility.address.province')}
          fullWidth
          margin="normal"
          defaultValue={province}
          InputLabelProps={{ shrink: true, sx: { fontWeight: 400 } }}
          onChange={provinceChangeHandler}
        />
        <TextField
          required
          label={t('facility.address.city')}
          fullWidth
          margin="normal"
          defaultValue={city}
          InputLabelProps={{ shrink: true, sx: { fontWeight: 400 } }}
          onChange={cityChangeHandler}
        />
        <TextField
          required
          label={t('facility.address.address1')}
          fullWidth
          margin="normal"
          defaultValue={address1}
          InputLabelProps={{ shrink: true, sx: { fontWeight: 400 } }}
          onChange={address1ChangeHandler}
        />
        <TextField
          label={t('facility.address.address2')}
          fullWidth
          margin="normal"
          defaultValue={address2}
          InputLabelProps={{ shrink: true, sx: { fontWeight: 400 } }}
          onChange={address2ChangeHandler}
        />
      </DialogContent>
      <DialogActions className={'facility-create-action-container'}>
        <Button
          className={'facility-dialog-cancel-button'}
          onClick={onClickCancel}
        >
          {t('button.cancel')}
        </Button>
        <Button
          variant="contained"
          className={'facility-dialog-edit-button'}
          onClick={onClickUpdate}
        >
          {t('button.edit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
