export interface IVehicleStatus {
  batteryLevel: number;
  chargingStatus: string;
  movingStatus: string;
  lockStatus: string;
  serviceStatus: string;
  currentStation: string;
  geofenceStatus: string;
  lastUpdatedTime: Date;
  locationSource: string;
  position?: IPosition;
  serviceStartTime?: Date;
}

export interface IPosition {
  lng: number;
  lat: number;
}

export class Vehicle implements IVehicleStatus {
  id: string;
  name: string;
  model: string;
  ble: {
    uuid: string;
    pass: string;
  };
  batteryLevel: number;
  chargingStatus: string;
  movingStatus: string;
  lockStatus: string;
  serviceStatus: string;
  currentStation: string;
  geofenceStatus: string;
  lastUpdatedTime: Date;
  locationSource: string;
  position?: IPosition;
  serviceStartTime?: Date;

  constructor(
    id: string,
    name: string,
    model: string,
    ble: {
      uuid: string;
      pass: string;
    },
    batteryLevel: number,
    chargingStatus: string,
    movingStatus: string,
    lockStatus: string,
    serviceStatus: string,
    currentStation: string,
    geofenceStatus: string,
    lastUpdatedTime: Date,
    locationSource: string,
    position?: {
      lng: number;
      lat: number;
    },
    serviceStartTime?: Date
  ) {
    this.id = id;
    this.name = name;
    this.model = model;
    this.ble = ble;
    this.batteryLevel = batteryLevel;
    this.chargingStatus = chargingStatus;
    this.movingStatus = movingStatus;
    this.lockStatus = lockStatus;
    this.serviceStatus = serviceStatus;
    this.currentStation = currentStation;
    this.geofenceStatus = geofenceStatus;
    this.locationSource = locationSource;
    this.lastUpdatedTime = lastUpdatedTime;
    this.position = position;
    this.serviceStartTime = serviceStartTime;
  }

  updateStatus(vehicleStatus: IVehicleStatus): Vehicle {
    return new Vehicle(
      this.id,
      this.name,
      this.model,
      this.ble,
      vehicleStatus.batteryLevel,
      vehicleStatus.chargingStatus,
      vehicleStatus.movingStatus,
      vehicleStatus.lockStatus,
      vehicleStatus.serviceStatus,
      vehicleStatus.currentStation,
      vehicleStatus.geofenceStatus,
      vehicleStatus.lastUpdatedTime,
      vehicleStatus.locationSource,
      vehicleStatus.position,
      vehicleStatus.serviceStartTime
    );
  }
}

export class VehicleCommand {
  lock: string;
  horn: string;
  createdTime: Date;

  constructor(lock: string, horn: string, createdTime: Date) {
    this.lock = lock;
    this.horn = horn;
    this.createdTime = createdTime;
  }
}
