import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useVehicles } from '../../contexts/vehicles';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Paper,
  Typography,
  Box,
  SelectChangeEvent,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import {
  Battery30,
  Battery50,
  Battery80,
  BatteryFull,
  Lock,
  QuestionMark,
  GpsFixed,
  GpsOff,
  PlayCircleFilledOutlined,
  Stop
} from '@mui/icons-material';
import UnLock from '../../assets/images/unlock-icon.svg';
import { format, isFuture } from 'date-fns';
import { getRentalDuration } from '../../lib/utils/getRentalDuraion';
import { SelectStationDialog, DialogType } from './VehicleDialog';
import { DialogType as OperationAlertDialogType } from './OperationAlertDialog';
import { Vehicle } from '../../data/vehicle';
import { useOperationAlertDialog } from './OperationAlertDialog';

import { get as getVehicle } from '../../lib/api/rest/vehicle/get';

export function VehiclesList() {
  const history = useNavigate();
  const { t } = useTranslation();
  const {
    vehicles,
    isLoading,
    stations,
    rentVehicle,
    fetch,
    allocateVehicleToStation,
    returnVehicle
  } = useVehicles();
  const [date, setDate] = useState(new Date());
  const [openSelectStation, setOpenSelectStation] = useState(false);
  const [targetVehicle, setTargetVehicle] = useState<Vehicle>();
  const [type, setType] = useState<DialogType>(0);
  const [currentStation, setCurrentStation] = useState<string>('');
  const { open, showLoading } = useOperationAlertDialog();

  useEffect(() => {
    setInterval(() => setDate(new Date()), 10000);
  }, []);

  const handleCardClick = (vehicleId: string) => {
    history(`/vehicles/${vehicleId}`);
  };
  const handleDialogClose = (reload?: boolean) => {
    setOpenSelectStation(false);
    setTargetVehicle(undefined);
    if (reload) fetch();
  };

  const onChangeStation = (e: SelectChangeEvent) => {
    let { value } = e.target;
    if (value) {
      setCurrentStation(e.target.value);
    } else {
      setCurrentStation('');
    }
  };
  const onClickRent = (targetVehicle: Vehicle) => {
    if (targetVehicle) {
      const onClickOk = async () => {
        try {
          showLoading(true);

          const { serviceStatus } = await getVehicle(targetVehicle.id);
          if (serviceStatus !== targetVehicle.serviceStatus) {
            throw new Error('Service Status Is Not Latest');
          }

          await rentVehicle(targetVehicle.id, targetVehicle.currentStation);
          fetch();
        } catch (error) {
          if ((error as Error).message === 'Service Status Is Not Latest') {
            throw Error(t('dialog.serviceStatusNotLatest.error'));
          }
          throw Error(t('vehicle.dailog.rent.error'));
        } finally {
          showLoading(false);
        }
      };
      open(
        t('vehicle.dialog.rent.title'),
        `${t('vehicle.dialog.rent.content')}`,
        () => onClickOk,
        [targetVehicle.name],
        OperationAlertDialogType.DEFAULT
      );
    }
  };
  return (
    <Grid xs={12} item>
      {isLoading ? (
        <LinearProgress color="secondary" />
      ) : (
        <Paper elevation={1}>
          <Box className="vehicle-filter-container">
            <FormControl fullWidth className={'vehicle-filter'}>
              <InputLabel>{t('vehicle.station.filter.select')}</InputLabel>
              <Select
                inputProps={{ className: 'vehicle-filter-input' }}
                MenuProps={{
                  PaperProps: { className: 'vehicle-filter-paper' }
                }}
                defaultValue={''}
                value={currentStation}
                onChange={onChangeStation}
                label={t('vehicle.station.filter.select')}
              >
                <MenuItem className="vehicle-dropdown-list" value={''} key={''}>
                  {t('vehicle.station.filter.nofilter')}
                </MenuItem>
                {stations?.map((station) => (
                  <MenuItem
                    className="vehicle-dropdown-list"
                    value={station.id}
                    key={station.id}
                  >
                    {`${station.name}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className="vehicle-list-container">
            {vehicles
              ?.filter((vehicle) =>
                currentStation
                  ? vehicle.currentStation === currentStation
                  : true
              )
              .map((vehicle) => (
                <Card className={'vehicle-card'} key={vehicle.id}>
                  <CardContent className={'vehicle-card-content'}>
                    <Grid container direction="column">
                      <Grid
                        className={'vehicle-card-header-container'}
                        container
                        item
                        xs={12}
                      >
                        <Grid
                          className={'vehicle-card-header-container'}
                          container
                          flexWrap={'nowrap'}
                          item
                          xs={9}
                        >
                          {vehicle.locationSource === 'gnss' ? (
                            <GpsFixed
                              color="success"
                              className="vehicle-list-gps-icon"
                            />
                          ) : (
                            <GpsOff
                              color="disabled"
                              className="vehicle-list-gps-icon"
                            />
                          )}
                          <Typography
                            className={
                              'vehicle-card-title vehicle-card-title-color'
                            }
                            onClick={() => handleCardClick(vehicle.id)}
                          >
                            {vehicle.name || vehicle.id}
                          </Typography>
                          <Typography className={'vehicle-card-title-caption'}>
                            {`(${vehicle.model})`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        className={'vehicle-status-button-container'}
                        item
                        xs={12}
                        justifyContent={'flex-end'}
                      >
                        {vehicle.serviceStatus === 'toBeSetup' && (
                          <Button
                            onClick={() => {
                              setTargetVehicle(vehicle);
                              setOpenSelectStation(true);
                              setType(DialogType.SETUP);
                            }}
                            className={'vehicle-status-button'}
                            variant="contained"
                          >
                            {t('vehicle.card.status.setup')}
                          </Button>
                        )}
                        {vehicle.serviceStatus === 'isReady' && (
                          <Button
                            className={'vehicle-status-button'}
                            variant="contained"
                            onClick={() => onClickRent(vehicle)}
                            startIcon={<PlayCircleFilledOutlined />}
                          >
                            {t('vehicle.card.status.rent')}
                          </Button>
                        )}
                        {vehicle.serviceStatus === 'inService' && (
                          <Button
                            className={'vehicle-status-button'}
                            variant="contained"
                            onClick={() => {
                              setTargetVehicle(vehicle);
                              setOpenSelectStation(true);
                              setType(DialogType.RETURN);
                            }}
                            startIcon={<Stop />}
                          >
                            {t('vehicle.card.status.return')}
                          </Button>
                        )}
                      </Grid>
                      {vehicle.serviceStatus === 'toBeSetup' && (
                        <Typography variant="caption" color="tomato">
                          {t('vehicle.serviceStatus.option.toBeSetup.message')}
                        </Typography>
                      )}
                      <Grid
                        xs={12}
                        item
                        container
                        className={'vehicle-card-header-container'}
                      >
                        <Grid
                          item
                          xs={12}
                          padding={'0.5rem 0 0.5rem 0'}
                          display={'flex'}
                          width={'inherit'}
                          justifyContent={'space-between'}
                        >
                          <Typography className={'vehicle-card-subheader'}>
                            {t('vehicle.connectionTime')}
                          </Typography>
                          <Typography
                            className={'vehicle-card-subheader-value'}
                          >
                            {format(vehicle.lastUpdatedTime, 'MM/dd HH:mm:ss')}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          padding={'0 0 0.5rem 0'}
                          display={'flex'}
                          width={'inherit'}
                          justifyContent={'space-between'}
                        >
                          <Typography className={'vehicle-card-subheader'}>
                            {t('vehicle.serviceStatus.title')}
                          </Typography>
                          <Typography
                            className={'vehicle-card-subheader-value'}
                          >
                            {vehicle.serviceStatus === 'toBeSetup'
                              ? t('vehicle.serviceStatus.option.toBeSetup')
                              : vehicle.serviceStatus === 'inService'
                              ? t('vehicle.serviceStatus.option.inService')
                              : vehicle.serviceStatus === 'isReady'
                              ? t('vehicle.serviceStatus.option.isReady')
                              : vehicle.serviceStatus === 'notReady'
                              ? t('vehicle.serviceStatus.option.notReady')
                              : t('global.noData')}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          padding={'0 0 0.5rem 0'}
                          display={'flex'}
                          width={'inherit'}
                          justifyContent={'space-between'}
                        >
                          <Typography className={'vehicle-card-subheader'}>
                            {t('vehicle.inService.duration')}
                          </Typography>
                          <Typography
                            className={'vehicle-card-subheader-value'}
                          >
                            {vehicle.serviceStatus !== 'inService'
                              ? '---'
                              : !vehicle.serviceStartTime
                              ? t('global.noData')
                              : isFuture(vehicle.serviceStartTime)
                              ? t('global.invalid')
                              : getRentalDuration(
                                  vehicle.serviceStartTime,
                                  date
                                ).days >= 1
                              ? t('vehicle.inService.duration.over24h')
                              : getRentalDuration(
                                  vehicle.serviceStartTime,
                                  date
                                ).hours +
                                'h ' +
                                getRentalDuration(
                                  vehicle.serviceStartTime,
                                  date
                                ).minutes +
                                'm'}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid xs={12} item container>
                        <Grid
                          item
                          xs={6}
                          className={'vehicle-card-button-icons'}
                        >
                          <Box className="vehicle-card-buttom-value">
                            <Box className="vehicle-card-icon">
                              {vehicle.lockStatus === 'locked' ? (
                                <Lock className={'vehicle-card-img'} />
                              ) : vehicle.lockStatus === 'unlocked' ? (
                                <img src={UnLock} alt="unlock-icon" />
                              ) : (
                                <QuestionMark className={'vehicle-card-img'} />
                              )}
                            </Box>
                            <Typography className={'vehicle-card-icon-value'}>
                              {vehicle.lockStatus === 'locked'
                                ? t('vehicle.lock.option.lock')
                                : vehicle.lockStatus === 'unlocked'
                                ? t('vehicle.lock.option.unlock')
                                : t('global.noData')}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          className={'vehicle-card-button-icons'}
                        >
                          <Box className="vehicle-card-buttom-value">
                            <Box className="vehicle-card-icon">
                              {0 < vehicle.batteryLevel &&
                              vehicle.batteryLevel <= 30 ? (
                                <Battery30 className={'vehicle-card-img'} />
                              ) : 30 < vehicle.batteryLevel &&
                                vehicle.batteryLevel <= 60 ? (
                                <Battery50 className={'vehicle-card-img'} />
                              ) : 60 < vehicle.batteryLevel &&
                                vehicle.batteryLevel <= 80 ? (
                                <Battery80 className={'vehicle-card-img'} />
                              ) : 80 < vehicle.batteryLevel ? (
                                <BatteryFull className={'vehicle-card-img'} />
                              ) : (
                                <QuestionMark className={'vehicle-card-img'} />
                              )}
                            </Box>
                            <Typography className={'vehicle-card-icon-value'}>
                              {vehicle.batteryLevel !== 0
                                ? vehicle.batteryLevel + '%'
                                : t('global.noData')}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
            {openSelectStation && targetVehicle && stations && (
              <SelectStationDialog
                type={type}
                stations={stations}
                isOpen={openSelectStation}
                vehicle={targetVehicle}
                dialogClose={handleDialogClose}
                allocateVehicleToStation={allocateVehicleToStation}
                returnVehicle={returnVehicle}
              />
            )}
          </Box>
        </Paper>
      )}
    </Grid>
  );
}
