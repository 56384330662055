import axios from 'axios';

import { Facility } from '../../../../data/facility';

type ResponseType = {
  id: string;
  name: string;
  geoLocation: { latitude: number; longitude: number };
  address: {
    countryOrRegion: string;
    province: string;
    city: string;
    address1: string;
    address2?: string;
    zipCode: string;
  };
  timeZoneID: string;
  businessHours: {
    startTime: string;
    endTime: string;
  };
  geofence: {
    type: string;
    config: {
      centerPoint: { latitude: number; longitude: number };
      radius: number;
    };
  } | null;
  description?: string;
  createdTime: string;
  createdBy: string;
  lastUpdatedTime: string;
  updatedBy: string;
};

export const get = async (id: string): Promise<Facility> => {
  const response = await axios.get<ResponseType>(`/facilities/${id}`);

  return new Facility(
    response.data.id,
    response.data.name,
    {
      lat: response.data.geoLocation.latitude,
      lng: response.data.geoLocation.longitude
    },
    response.data.address,
    response.data.timeZoneID,
    response.data.businessHours,
    new Date(response.data.createdTime),
    response.data.createdBy,
    new Date(response.data.lastUpdatedTime),
    response.data.updatedBy,
    response.data.geofence != null
      ? {
          ...response.data.geofence,
          config: {
            ...response.data.geofence.config,
            centerPoint: {
              lat: response.data.geofence.config.centerPoint.latitude,
              lng: response.data.geofence.config.centerPoint.longitude
            }
          }
        }
      : undefined,
    response.data.description
  );
};
