import axios from 'axios';

import { Vehicle } from '../../../../data/vehicle';

export interface ListRequestParams {
  cursor?: string;
  limit?: number;
}

type ResponseType = {
  vehicles: Array<{
    id: string;
    name: string;
    model: string;
    ble: {
      uuid: string;
      pass: string;
    };
    batteryLevel: number;
    chargingStatus: string;
    movingStatus: string;
    lockStatus: string;
    serviceStatus: string;
    currentStation: string;
    geoLocation: { latitude: number; longitude: number } | null;
    locationSource: string;
    serviceStartTime?: string;
    geofenceStatus: string;
    lastUpdatedTime: string;
  }>;
  nextCursor: string;
};

interface IMetadata {
  nextCursor: string;
}

export const list = async (
  params?: ListRequestParams
): Promise<{ metadata: IMetadata; vehicles: Array<Vehicle> }> => {
  const response = await axios.get<ResponseType>('/vehicles', {
    params: {
      cursor: params?.cursor,
      limit: params?.limit
    }
  });

  const metadata: IMetadata = { nextCursor: response.data.nextCursor };
  const vehicles = response.data.vehicles.map(
    (vehicle) =>
      new Vehicle(
        vehicle.id,
        vehicle.name,
        vehicle.model,
        vehicle.ble,
        vehicle.batteryLevel,
        vehicle.chargingStatus,
        vehicle.movingStatus,
        vehicle.lockStatus,
        vehicle.serviceStatus,
        vehicle.currentStation,
        vehicle.geofenceStatus,
        new Date(vehicle.lastUpdatedTime),
        vehicle.locationSource,
        vehicle.geoLocation != null
          ? {
              lat: vehicle.geoLocation.latitude,
              lng: vehicle.geoLocation.longitude
            }
          : undefined,
        vehicle.serviceStartTime
          ? new Date(vehicle.serviceStartTime)
          : undefined
      )
  );

  return { metadata, vehicles };
};
