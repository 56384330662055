import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useFacility } from '../../../contexts/facility';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  Divider
} from '@mui/material';
import { GoogleMap, Marker, Circle } from '@react-google-maps/api';

import { Facility } from '../../../data/facility';

import { mapOptions } from '../../../assets/mapOptions';

interface UpdateFacilityDialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  facility: Facility;
}

export function SetupGeofenceDialog(props: UpdateFacilityDialogProps) {
  const { t } = useTranslation();
  const [markerPosition, setMarkerPosition] = useState<
    google.maps.LatLng | null | undefined
  >();
  const [radius, setRadius] = useState<number>(100);
  const [errorMessage, setErrorMessage] = useState<string>();

  const { update } = useFacility();

  const radiusChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (parseInt(event.target.value, 10) < 100) {
      setRadius(100);
      setErrorMessage(t('form.message.100m'));
    } else {
      setErrorMessage(undefined);
    }
    setRadius(parseInt(event.target.value, 10));
  };

  const onLoadMarkerHandler = (marker: google.maps.Marker) => {
    setMarkerPosition(marker.getPosition());
  };

  const onDragEndMarkerHandler = (event: google.maps.MapMouseEvent) => {
    setMarkerPosition(event.latLng);
  };

  const onClickSetup = async () => {
    if (radius < 100) {
      setErrorMessage(t('form.message.100m'));
      return;
    } else {
      setErrorMessage(undefined);
    }

    if (!radius || !markerPosition) {
      setErrorMessage(t('form.message.unfilled'));
      return;
    }

    setErrorMessage(undefined);
    await update(props.facility.id, {
      name: props.facility.name,
      geofence: {
        type: 'Circular',
        config: {
          centerPoint: { lat: markerPosition.lat(), lng: markerPosition.lng() },
          radius
        }
      }
    });

    setMarkerPosition(undefined);
    setRadius(100);
    props.setIsOpen(false);
  };

  const onClickCancel = () => {
    setMarkerPosition(undefined);
    setRadius(100);
    setErrorMessage(undefined);
    props.setIsOpen(false);
  };

  return (
    <Dialog
      open={props.isOpen}
      PaperProps={{ className: 'facility-geofence-dialog-container' }}
    >
      <DialogTitle className={'facility-edit-title'} component={'div'}>
        {t('facility.geofence.dialog.setup.title')}
      </DialogTitle>
      <Divider className={'facility-edit-divider'} />
      <DialogContent>
        <TextField
          required
          label={t('facility.geofence.circular.radius')}
          type="number"
          InputProps={{ inputProps: { min: 100 } }}
          margin="normal"
          defaultValue={radius}
          onChange={radiusChangeHandler}
        />
        <Box className="geofence-map ">
          <Typography className="geofence-map-label">
            {t('facility.geofence.circular.center') + ' *'}
          </Typography>
          <Box className="geofence-map-border geofence-map-radius">
            <GoogleMap
              zoom={16}
              mapContainerStyle={{ width: 'inherit', height: '300px' }}
              center={markerPosition || props.facility.position}
              clickableIcons={false}
              options={mapOptions}
            >
              <Marker
                draggable
                position={markerPosition || props.facility.position}
                onLoad={(marker) => onLoadMarkerHandler(marker)}
                onDragEnd={(event) => onDragEndMarkerHandler(event)}
              />
              <Circle
                center={markerPosition || props.facility.position}
                radius={radius}
              />
            </GoogleMap>
          </Box>
        </Box>
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </DialogContent>
      <DialogActions className={'facility-create-action-container'}>
        <Button
          className={'facility-dialog-cancel-button'}
          onClick={onClickCancel}
        >
          {t('button.cancel')}
        </Button>
        <Button
          className={'facility-dialog-edit-button'}
          onClick={onClickSetup}
        >
          {t('button.setup')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
