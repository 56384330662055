import React, { ReactNode, useReducer } from 'react';
import { useSubscription } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Vehicle, IVehicleStatus, VehicleCommand } from '../../data/vehicle';
import { Station } from '../../data/station';
import * as rest from '../../lib/api/rest';
import * as graphql from '../../lib/api/graphql';
import { VehicleContext } from './vehicle-context';
import { initailVehicleState, VehicleState } from './vehicle-state';
import { useParams } from 'react-router-dom';

type Action =
  | {
      type: 'FETCH';
    }
  | {
      type: 'FETCHED';
      payload: {
        vehicle?: Vehicle;
        commandHistory?: Array<VehicleCommand>;
        stations?: Array<Station>;
      };
    }
  | {
      type: 'UPDATE_PROFILE';
      payload: {
        vehicle: Vehicle;
      };
    }
  | {
      type: 'UPDATE_STATUS';
      payload: {
        vehicleStatus: IVehicleStatus;
      };
    }
  | {
      type: 'SENT_COMMAND';
      payload: {
        command: VehicleCommand;
      };
    }
  | {
      type: 'ERROR';
      payload: {
        error: Error;
      };
    };

function reducer(state: VehicleState, action: Action): VehicleState {
  switch (action.type) {
    case 'FETCH':
      return {
        ...state,
        isLoading: true
      };
    case 'FETCHED':
      return {
        ...state,
        isLoading: false,
        vehicle: action.payload.vehicle,
        commandHistory: action.payload.commandHistory,
        stations: action.payload.stations
      };
    case 'UPDATE_PROFILE':
      return {
        ...state,
        vehicle: action.payload.vehicle
      };
    case 'UPDATE_STATUS':
      return {
        ...state,
        vehicle: state.vehicle?.updateStatus(action.payload.vehicleStatus)
      };
    case 'SENT_COMMAND':
      return {
        ...state,
        commandHistory:
          !state.commandHistory || state.commandHistory.length === 0
            ? [action.payload.command]
            : state.commandHistory.length < 10
            ? [action.payload.command, ...state.commandHistory]
            : [action.payload.command, ...state.commandHistory].slice(
                0,
                9 - state.commandHistory.length
              )
      };
    case 'ERROR':
      return {
        ...state,
        error: action.payload.error
      };
  }
}

interface VehicleProviderProps {
  children: ReactNode;
}
export enum APIType {
  vehicle,
  commands,
  all
}
export function VehicleProvider(props: VehicleProviderProps) {
  const [state, dispatch] = useReducer(reducer, initailVehicleState);
  const { user } = useAuth0();
  const facilityID: string =
    user?.['https://whill-share.com/md/app_metadata'].facility_id;
  const vehicleID = useParams()['id'];
  const onSubscriptionVehicleStatus = (
    data?: graphql.vehicle.SubscriptionResponse
  ): void => {
    if (!data || state.isLoading) return;
    const vehicleStatus: IVehicleStatus = {
      batteryLevel: data.onPublishVehicleStatus.batteryLevel,
      chargingStatus: data.onPublishVehicleStatus.chargingStatus,
      movingStatus: data.onPublishVehicleStatus.movingStatus,
      lockStatus: data.onPublishVehicleStatus.lockStatus,
      serviceStatus: data.onPublishVehicleStatus.serviceStatus,
      currentStation: data.onPublishVehicleStatus.currentStationID,
      position: {
        lng: data.onPublishVehicleStatus.geoLocationLongitude,
        lat: data.onPublishVehicleStatus.geoLocationLatitude
      },
      geofenceStatus: data.onPublishVehicleStatus.geofenceStatus,
      serviceStartTime: data.onPublishVehicleStatus.serviceStartTime
        ? new Date(data.onPublishVehicleStatus.serviceStartTime)
        : undefined,
      locationSource: data.onPublishVehicleStatus.locationSource,
      lastUpdatedTime: new Date(data.onPublishVehicleStatus.createdTime)
    };
    dispatch({ type: 'UPDATE_STATUS', payload: { vehicleStatus } });
  };

  useSubscription<graphql.vehicle.SubscriptionResponse>(
    graphql.vehicle.onPublishVehicleStatusPerVehicle,
    {
      variables: { facilityID, vehicleID },
      onSubscriptionData: (option) =>
        onSubscriptionVehicleStatus(option.subscriptionData.data)
    }
  );

  const update = async (
    id: string,
    props: rest.vehicle.UpdateVehicleProps
  ): Promise<void> => {
    try {
      const vehicle = await rest.vehicle.update(id, props);
      dispatch({ type: 'UPDATE_PROFILE', payload: { vehicle } });
    } catch (error) {
      dispatch({ type: 'ERROR', payload: { error: error as Error } });
    }
  };

  const command = async (
    id: string,
    props: rest.vehicle.command.SendCommandProps
  ): Promise<void> => {
    try {
      await rest.vehicle.command.send(id, props);
      dispatch({
        type: 'SENT_COMMAND',
        payload: {
          command: new VehicleCommand(props.lock, props.horn, new Date())
        }
      });
    } catch (error) {
      dispatch({ type: 'ERROR', payload: { error: error as Error } });
    }
  };

  const fetch = async (type?: APIType): Promise<void> => {
    if (!vehicleID) {
      return;
    }
    dispatch({ type: 'FETCH' });
    try {
      let vehicle;
      let commands;
      let stations;
      if (type === APIType.vehicle) {
        vehicle = await rest.vehicle.get(vehicleID);
      } else {
        vehicle = await rest.vehicle.get(vehicleID);
        const commandsHistory = await rest.vehicle.command.list(vehicleID, {
          limit: 10
        });
        commands = commandsHistory.commands;
        const stationsList = await rest.station.list();
        stations = stationsList.stations;
      }
      dispatch({
        type: 'FETCHED',
        payload: {
          vehicle,
          commandHistory: commands ?? state.commandHistory,
          stations: stations ?? state.stations
        }
      });
    } catch (error) {
      dispatch({ type: 'ERROR', payload: { error: error as Error } });
    }
  };
  const allocateVehicleToStation = async (
    vehicleId: string,
    stationId: string
  ): Promise<void> => {
    await rest.vehicle.setup(vehicleId, stationId);
  };
  const rentVehicle = async (
    vehicleId: string,
    stationId: string
  ): Promise<void> => {
    await rest.vehicle.rent(vehicleId, stationId);
  };
  const returnVehicle = async (
    vehicleId: string,
    stationId: string
  ): Promise<void> => {
    await rest.vehicle.returnVehicle(vehicleId, stationId);
  };
  return (
    <VehicleContext.Provider
      value={{
        ...state,
        update,
        command,
        fetch,
        allocateVehicleToStation,
        rentVehicle,
        returnVehicle
      }}
    >
      {props.children}
    </VehicleContext.Provider>
  );
}
