import { Facility } from '../../data/facility';

export interface FacilityState {
  isLoading: boolean;
  facility?: Facility;
  error?: Error;
}

export const initailFacilityState: FacilityState = {
  isLoading: false
};
