import React, { ReactNode } from 'react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { grey } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    background: {
      default: grey[50]
    },
    primary: {
      main: '#0075b8',
      light: '#0095eb'
    },
    secondary: {
      main: '#666666'
    }
  },
  typography: {
    subtitle2: {
      fontWeight: 400
    },
    fontFamily: [
      'DIN Pro',
      'Hiragino Kaku Gothic ProN',
      'Meiryo',
      'sans-serif'
    ].join(',')
  },
  mixins: {
    toolbar: {
      minHeight: 72
    }
  }
});

interface Props {
  children: ReactNode;
}

export function StyleTemplate(props: Props) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
}
