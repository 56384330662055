export class Station {
  id: string;
  name: string;
  position: {
    lat: number;
    lng: number;
  };
  capacity: number;
  description?: string;
  createdTime?: Date;
  createdBy?: string;
  lastUpdatedTime?: Date;
  updatedBy?: string;

  constructor(
    id: string,
    name: string,
    position: { lat: number; lng: number },
    capacity: number,
    description?: string,
    createdTime?: Date,
    createdBy?: string,
    lastUpdatedTime?: Date,
    updatedBy?: string
  ) {
    this.id = id;
    this.name = name;
    this.position = position;
    this.capacity = capacity;
    this.description = description;
    this.createdTime = createdTime;
    this.createdBy = createdBy;
    this.lastUpdatedTime = lastUpdatedTime;
    this.updatedBy = updatedBy;
  }
}

export class Metadata {
  nextCursor: string;

  constructor(nextCursor: string) {
    this.nextCursor = nextCursor;
  }
}
