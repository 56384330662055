import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

import { utcToZonedTime, format } from 'date-fns-tz';
import {
  ICallTarget,
  ILowBatteryTarget,
  IGeofenceTarget,
  NotificationType,
  onPublishNotification,
  SubscriptionResponse
} from '../../lib/api/graphql/notification/subscribe';

import { useSubscription } from '@apollo/client';
import { useSnackbar, SnackbarKey } from 'notistack';

import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  children?: ReactNode;
}

export function NotificationReciever(props: Props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user } = useAuth0();
  const { t } = useTranslation();
  const facilityID: string =
    user?.['https://whill-share.com/md/app_metadata'].facility_id;

  const action = (key: SnackbarKey) => (
    <IconButton
      aria-label="close"
      color="inherit"
      sx={{ p: 0.5 }}
      onClick={() => closeSnackbar(key)}
    >
      <CloseIcon />
    </IconButton>
  );

  const onSubscriptionNotification = (data?: SubscriptionResponse) => {
    if (!data) {
      return;
    }

    const date = new Date(data.onPublishNotification.createdTime);
    const zonedDate = utcToZonedTime(
      date,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    switch (data.onPublishNotification.type) {
      case NotificationType.CALL:
        let callTarget = JSON.parse(
          data.onPublishNotification.target
        ) as ICallTarget;
        enqueueSnackbar(
          `${t('notification.call.message')}: ${
            callTarget.stationName
          } (${format(zonedDate, 'HH:mm:ss')})`,
          {
            action,
            variant: 'warning'
          }
        );
        break;

      case NotificationType.LOW_BATTERY:
        let lowBatteryTarget = JSON.parse(
          data.onPublishNotification.target
        ) as ILowBatteryTarget;
        enqueueSnackbar(
          <>
            {t('notification.battery.shortage')} :
            {
              <Link
                className="snackbar-notification-link"
                to={`/vehicles/${lowBatteryTarget.vehicleID}`}
              >
                <Typography className="snackbar-notification-source-name">
                  {lowBatteryTarget.vehicleName}
                </Typography>
              </Link>
            }
            ({format(zonedDate, 'HH:mm:ss')})
          </>,
          {
            action,
            variant: 'warning'
          }
        );
        break;

      case NotificationType.GEOFENCE:
        var geofenceTarget = JSON.parse(
          data.onPublishNotification.target
        ) as IGeofenceTarget;
        enqueueSnackbar(
          <>
            {t('notification.geofence.outside')} :
            {
              <Link
                className="snackbar-notification-link"
                to={`/vehicles/${geofenceTarget.vehicleID}`}
              >
                <Typography className="snackbar-notification-source-name">
                  {geofenceTarget.vehicleName}
                </Typography>
              </Link>
            }
            ({format(zonedDate, 'HH:mm:ss')})
          </>,
          {
            action,
            variant: 'error'
          }
        );
        break;
    }
  };

  useSubscription<SubscriptionResponse>(onPublishNotification, {
    variables: { facilityID },
    onSubscriptionData: (option) =>
      onSubscriptionNotification(option.subscriptionData.data)
  });

  return <>{props.children}</>;
}
