import axios from 'axios';

import { VehicleCommand } from '../../../../../data/vehicle';

export interface ListRequestParams {
  cursor?: string;
  limit?: number;
}

type ResponseType = {
  commands: Array<{
    command: {
      lock: string;
      horn: string;
    };
    createdTime: string;
  }>;
  nextCursor: string;
};

interface IMetadata {
  nextCursor: string;
}

export const list = async (
  id: string,
  params?: ListRequestParams
): Promise<{ metadata: IMetadata; commands: Array<VehicleCommand> }> => {
  const response = await axios.get<ResponseType>(`/vehicles/${id}/commands`, {
    params: {
      cursor: params?.cursor,
      limit: params?.limit
    }
  });

  const metadata: IMetadata = { nextCursor: response.data.nextCursor };
  const commands = response.data.commands.map(
    (command) =>
      new VehicleCommand(
        command.command.lock,
        command.command.horn,
        new Date(command.createdTime)
      )
  );

  return { metadata, commands };
};
