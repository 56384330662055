import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';

import { Vehicle } from '../../../data/vehicle';

import { useVehicle } from '../../../contexts/vehicle';

interface UpdateVehicleDialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  vehicle: Vehicle;
}

export function UpdateVehicleDialog(props: UpdateVehicleDialogProps) {
  const { t } = useTranslation();
  const { update } = useVehicle();
  const [name, setName] = useState<string>(props.vehicle.name);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    setName(props.vehicle.name);
  }, [props.isOpen]);

  const nameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onClickUpdate = async () => {
    if (name) {
      setErrorMessage(undefined);
      await update(props.vehicle.id, { name });
      props.setIsOpen(false);
    } else {
      setErrorMessage(t('form.message.unfilled'));
    }
  };

  const onClickCancel = () => {
    props.setIsOpen(false);
  };

  return (
    <Dialog
      open={props.isOpen}
      PaperProps={{ className: 'dialog-alert-container' }}
    >
      <DialogTitle className={'dialog-title'}>
        {t('vehicle.dialog.update.title')}
      </DialogTitle>
      <DialogContent className={'dialog-content-alert'}>
        <TextField
          required
          autoFocus
          label={t('vehicle.name')}
          fullWidth
          margin="normal"
          defaultValue={name}
          InputLabelProps={{ shrink: true, sx: { fontWeight: 400 } }}
          onChange={nameChangeHandler}
        />
        {<Typography color="error">{errorMessage}</Typography>}
      </DialogContent>
      <DialogActions className={'dialog-action-alert'}>
        <Button onClick={onClickCancel} className={'dialog-cancel-button'}>
          {t('button.cancel')}
        </Button>
        <Button className={'dialog-ok-button'} onClick={onClickUpdate}>
          {t('button.edit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
