import React, { useState } from 'react';
import { useFacility } from '../../contexts/facility';
import { useStations } from '../../contexts/stations';
import { Station } from '../../data/station';
import { Paper } from '@mui/material';
import { GoogleMap, Marker } from '@react-google-maps/api';
import stationIcon from '../../assets/images/station-icon.svg';
import { mapOptions } from '../../assets/mapOptions';

export function StationsMap() {
  const { stations } = useStations();
  const [loadedMarkers, setLoadedMarkers] = useState<{
    [id: string]: google.maps.Marker;
  }>({});

  const [loadedWindows, setLoadedWindows] = useState<{
    [id: string]: google.maps.InfoWindow;
  }>({});

  const [map, setMap] = useState<google.maps.Map>();
  const { facility } = useFacility();

  const unsetWindow = (id: string) => {
    setLoadedWindows((prevState) => {
      return Object.keys(prevState).reduce(
        (object: typeof loadedWindows, key) => {
          if (key !== id) {
            object[key] = prevState[key];
          }
          return object;
        },
        {}
      );
    });
  };

  const onClickMarkerHandler = (station: Station): void => {
    if (loadedWindows[station.id]) {
      return;
    }
    const window = new google.maps.InfoWindow({
      content: `<div><div class="station-map-info-line" ></div><div class="station-map-info-window">${station.name}</div></div>`
    });
    setLoadedWindows((prevState) => ({
      [station.id]: window,
      ...prevState
    }));
    window.open(map, loadedMarkers[station.id]);
    window.addListener('closeclick', () => {
      unsetWindow(station.id);
    });
  };

  const onLoadMarkerHandler = (
    marker: google.maps.Marker,
    station: Station
  ): void => {
    setLoadedMarkers((prevState) => {
      return { [station.id]: marker, ...prevState };
    });

    const window = new google.maps.InfoWindow({
      content: `<div><div class="station-map-info-line" ></div><div class="station-map-info-window">${station.name}</div></div>`
    });
    setLoadedWindows((prevState) => ({
      [station.id]: window,
      ...prevState
    }));
    window.open(map, marker);
    window.addListener('closeclick', () => {
      unsetWindow(station.id);
    });
  };

  const onLoadMapHandler = (loadedMap: google.maps.Map) => {
    setMap(loadedMap);
  };

  return (
    <Paper elevation={1}>
      <GoogleMap
        zoom={17}
        mapContainerClassName={'station-map-container'}
        center={facility?.position}
        onLoad={(loadedMap) => {
          onLoadMapHandler(loadedMap);
        }}
        options={mapOptions}
      >
        {stations?.map((station) => (
          <Marker
            key={station.id}
            title={station.name}
            position={station.position}
            icon={stationIcon}
            onClick={(event) => onClickMarkerHandler(station)}
            onLoad={(marker) => onLoadMarkerHandler(marker, station)}
          />
        ))}
      </GoogleMap>
    </Paper>
  );
}
