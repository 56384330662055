import axios from 'axios';

import { Station } from '../../../../data/station';

export interface ListRequestParams {
  cursor?: string;
  limit?: number;
}

type ResponseType = {
  stations: Array<{
    id: string;
    name: string;
    geoLocation: {
      latitude: number;
      longitude: number;
    };
    description: string;
    capacity: number;
    facilityID: string;
    createdTime: string;
    createdBy: string;
    lastUpdateTime: string;
    updatedBy: string;
  }>;
  nextCursor: string;
};

export const list = async (
  params?: ListRequestParams
): Promise<{ metadata: any; stations: Array<Station> }> => {
  const response = await axios.get<ResponseType>('/stations', {
    params: {
      cursor: params?.cursor,
      limit: params?.limit
    }
  });

  const metadata = { nextCursor: response.data.nextCursor };
  const stations = response.data.stations.map(
    (station) =>
      new Station(
        station.id,
        station.name,
        {
          lat: station.geoLocation.latitude,
          lng: station.geoLocation.longitude
        },
        station.capacity,
        station.description,
        new Date(station.createdTime),
        station.createdBy,
        new Date(station.lastUpdateTime),
        station.updatedBy
      )
  );

  return { metadata, stations };
};
