import axios from 'axios';

export interface DiscardRequestParams {}

export const discard = async (id: string): Promise<void> => {
  try {
    await axios.delete(`/stations/${id}`);
  } catch (error: any) {
    throw new Error(error.message);
  }
};
