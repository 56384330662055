import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languagedetector from 'i18next-browser-languagedetector';

const resources = {
  ja: {
    translation: {
      'global.dashboard': 'ダッシュボード',
      'global.facility': '施設',
      'global.stations': 'ステーション',
      'global.stations.list': 'ステーション一覧',
      'global.vehicles': '機体',
      'global.histories': '利用履歴',
      'global.settings': '設定',
      'global.notRegistered': '未登録',
      'global.logout': 'ログアウト',
      'global.invalid': '不正な値です',
      'global.noData': 'データなし',
      // button
      'button.create': '新規作成',
      'button.edit': '更新',
      'button.delete': '削除',
      'button.apply': '実行',
      'button.cancel': 'キャンセル',
      // 'button.disagree': '',
      'button.setup': '設定',
      'button.loadMore': 'さらにロード',
      // form
      'form.message.unfilled': '必須項目を入力してください',
      'from.message.select.vehicle': '機体を選択してください',
      'form.message.100m': '100m以上の数を入れてください。',
      // notification
      'notification.call.message': 'KIOSKからの呼び出しです',
      'notification.geofence.outside': '機体が設定エリアの外側へ出ました',
      'notification.battery.shortage':
        '返却された機体のバッテリーが低下しています',
      // facility
      'facility.address': '住所',
      'facility.address.countryOrRegion': '国',
      'facility.address.zipCode': '郵便番号',
      'facility.address.province': '都道府県',
      'facility.address.city': '市区町村',
      'facility.address.address': '住所',
      'facility.address.address1': '住所1（町名、丁目、番地）',
      'facility.address.address2': '住所2（建物名、部屋番号）',
      'facility.businessInformation': '営業情報',
      'facility.businessHour': '営業時間',
      'facility.businessHour.start': '開始時刻',
      'facility.businessHour.end': '終了時刻',
      'facility.description': '詳細',
      'facility.geofence': '域外アラート',
      'facility.geofence.dialog.setup.title': 'エリアを設定',
      'facility.geofence.type': 'タイプ',
      'facility.geofence.circular': '同心円',
      'facility.geofence.circular.radius': '半径(m)',
      'facility.geofence.circular.center': 'エリアの中心',
      'facility.dialog.update.title': '施設情報を更新',
      // station
      'station.name': 'ステーション名',
      'station.capacity': '収容可能数',
      'station.geoLocation': '設置場所',
      'station.description': '詳細',
      'station.vehicles': '機体一覧',
      'station.dialog.create.title': 'ステーションを新規作成',
      'station.dialog.update.title': 'ステーション情報を更新',
      'station.dialog.discard.title': 'ステーションを削除します',
      'station.dialog.discard.description': '以下のステーションを削除します。',
      'station.details.backButton': '一覧へ戻る',
      // vehicle
      'vehicle.id': 'ID',
      'vehicle.name': '機体名',
      'vehicles.status': 'ステータス',
      'vehicle.connectionTime': '最終通信時刻',
      'vehicle.serviceStatus.title': '機体状況',
      'vehicle.serviceStatus.option.inService': '利用中',
      'vehicle.serviceStatus.option.isReady': '利用可能',
      'vehicle.serviceStatus.option.notReady': '準備中',
      'vehicle.serviceStatus.option.toBeSetup': '未設定',
      'vehicle.serviceStatus.option.toBeSetup.message':
        '機体の初期設定をしてください',
      'vehicle.inService.duration': '利用時間',
      'vehicle.inService.duration.over24h': '24時間以上',
      'vehicle.lock': 'ロック',
      'vehicle.lock.option.lock': '施錠',
      'vehicle.lock.option.unlock': '解錠',
      'vehicle.battery': 'バッテリー',
      'vehicle.charging.title': '充電中',
      'vehicle.charging.option.true': '充電中',
      'vehicle.charging.option.false': '非充電中',
      'vehicle.command': 'コマンド',
      'vehicle.command.horn': 'ホーン',
      'vehicle.command.lock': 'ロック',
      'vehicle.command.history': 'コマンド履歴',
      'vehicle.command.history.caption': '(最大10件)',
      'vehicle.command.executeTime': '実行時刻',
      'vehicle.command.type': 'タイプ',
      'vehicle.dialog.update.title': '機体情報を更新',
      'vehicle.dialog.command.horn.title': 'ホーンを鳴らす',
      'vehicle.dialog.command.horn.description':
        '機体へホーンを鳴らすコマンドを送りますか？',
      'vehicle.dialog.noStations.pre': 'ステーションが登録されていません。',
      'vehicle.dialog.noStations.stationPage': ' ステーションページ ',
      'vehicle.dialog.noStations.post':
        'で新しいステーションを登録してください。',
      'vehicle.position.noData': '機体の位置情報が取得できませんでした',
      'vehicle.details.backButton': '一覧へ戻る',
      'vehicle.card.status.setup': '初期設定',
      'vehicle.card.status.rent': '貸出',
      'vehicle.card.status.return': '返却',
      'vehicle.dialog.setup.title': '初期設定',
      'vehicle.dialog.return.title': '返却',
      'vehicle.dialog.rent.title': '貸出',
      'vehicle.dialog.rent.content':
        '以下の機体を貸し出してレンタルを開始しますか？',
      'vehicle.dailog.rent.error': '機体の貸出処理でエラーが発生しました。',
      'vehicle.dialog.setup.submit.button': '実行',
      'select.station.dailog.allocation.error':
        '機体の初期設定でエラーが発生しました。',
      'select.station.dailog.return.error':
        '機体の返却処理でエラーが発生しました。',
      'dialog.serviceStatusNotLatest.error':
        '機体状況が最新でないため処理に失敗しました。ほかのユーザーが先に処理をした可能性があります。画面を更新して下さい。',
      'vehicle.station.filter.select': 'ステーションを選択',
      'vehicle.station.filter.nofilter': '選択なし',
      // trip
      'trip.nofilter': '選択なし',
      'trip.vehicle.id': '機体ID',
      'trip.vehicle.name': '機体名',
      'trip.distance': '走行距離(m)',
      'trip.duration': '利用時間',
      'trip.duration.over24h': '24時間以上',
      'trip.start.station': '貸出ステーション',
      'trip.start.time': '貸出時刻',
      'trip.end.station': '返却ステーション',
      'trip.end.time': '返却時刻',
      'trip.notReturned': '未返却',
      'trip.path.message.unavailable':
        '利用が短すぎるために経路の表示ができません',
      // help
      'help.title': 'ヘルプページ',
      'help.contact': '連絡先',
      'help.phoneNumber.caption': '(法人専用・平日9時~18時)',
      'help.contact.example': 'たとえばこんな時',
      'help.contact.example.escaltion': '緊急トラブルの連絡',
      'help.contact.example.repair': '修理のご依頼',
      'help.document': 'ドキュメント',
      'help.document.vehicle': '機体マニュアル',
      'help.document.kiosk': 'キオスクの使い方'
    }
  },
  en: {
    translation: {
      'global.dashboard': 'Dashboard',
      'global.facility': 'Facility',
      'global.stations': 'Stations',
      'global.stations.list': 'Stations List',
      'global.vehicles': 'Vehicles',
      'global.histories': 'Histories',
      'global.settings': 'settings',
      'global.notRegistered': 'Not Registered',
      'global.logout': 'Logout',
      'global.invalid': 'Invalid value',
      'global.noData': 'No data',
      // button
      'button.create': 'Create',
      'button.edit': 'Update',
      'button.delete': 'Delete',
      'button.apply': 'OK',
      'button.cancel': 'Cancel',
      // 'button.disagree': 'disagree',
      'button.setup': 'Setup',
      'button.loadMore': 'load more',
      // form
      'form.message.unfilled': 'Fill in required items',
      'from.message.select.vehicle': 'Select a vehicle',
      'form.message.100m': 'Fill in a number greater than 100.',
      // notification
      'notification.call.message': 'Call from KIOSK',
      'notification.geofence.outside': 'Vehicle is outside of geofence',
      'notification.battery.shortage': 'Returned vehicle battery low',
      // facility
      'facility.address': 'Address',
      'facility.address.countryOrRegion': 'Country',
      'facility.address.zipCode': 'Zip Code',
      'facility.address.province': 'Province',
      'facility.address.city': 'City',
      'facility.address.address': 'Address',
      'facility.address.address1': 'Address 1 (Street name, House number)',
      'facility.address.address2': 'Address 2 (Building name, Room number)',
      'facility.businessInformation': 'Business Information',
      'facility.businessHour': 'Business Hour',
      'facility.businessHour.start': 'Start',
      'facility.businessHour.end': 'End',
      'facility.description': 'Description',
      'facility.geofence': 'Geofence',
      'facility.geofence.dialog.setup.title': 'Setup Geofence',
      'facility.geofence.type': 'Type',
      'facility.geofence.circular': 'Circular',
      'facility.geofence.circular.radius': 'Radius(m)',
      'facility.geofence.circular.center': 'Geofence Center',
      'facility.dialog.update.title': 'Update a facility',
      // station
      'station.name': 'Name',
      'station.capacity': 'Capacity',
      'station.geoLocation': 'Location',
      'station.description': 'Description',
      'station.vehicles': 'Vehicle List',
      'station.dialog.create.title': 'Create a new Station',
      'station.dialog.update.title': 'Update a Station',
      'station.dialog.discard.title': 'Delete station?',
      'station.dialog.discard.description': 'Delete station below',
      'station.details.backButton': 'BACK TO LIST',
      // vehicle
      'vehicle.id': 'ID',
      'vehicle.name': 'Name',
      'vehicles.status': 'Status',
      'vehicle.connectionTime': 'Last Connected',
      'vehicle.serviceStatus.title': 'Service Status',
      'vehicle.serviceStatus.option.inService': 'In service',
      'vehicle.serviceStatus.option.isReady': 'Ready',
      'vehicle.serviceStatus.option.notReady': 'Not ready',
      'vehicle.serviceStatus.option.toBeSetup': 'Not set up',
      'vehicle.serviceStatus.option.toBeSetup.message':
        'Do the initial setup of the vehicle',
      'vehicle.inService.duration': 'Duration',
      'vehicle.inService.duration.over24h': 'Over 24h',
      'vehicle.lock': 'Lock',
      'vehicle.lock.option.lock': 'Lock',
      'vehicle.lock.option.unlock': 'Unlock',
      'vehicle.battery': 'Battery',
      'vehicle.charging.title': 'Charging',
      'vehicle.charging.option.true': 'Yes',
      'vehicle.charging.option.false': 'No',
      'vehicle.command': 'Command',
      'vehicle.command.horn': 'Horn',
      'vehicle.command.lock': 'Lock',
      'vehicle.command.history': 'Command History',
      'vehicle.command.history.caption': '(up to 10 records)',
      'vehicle.command.executeTime': 'Execute Time',
      'vehicle.command.type': 'Type',
      'vehicle.dialog.update.title': 'Update a vehicle',
      'vehicle.dialog.command.horn.title': 'Send a horn command',
      'vehicle.dialog.command.horn.description':
        'Do you send a commad to honk the horn?',
      'vehicle.position.noData': 'Vehicle position data unavailable',
      'vehicle.details.backButton': 'BACK TO DASHBOARD',
      'vehicle.card.status.setup': 'Setup',
      'vehicle.card.status.rent': 'Rent',
      'vehicle.card.status.return': 'Return',
      'vehicle.dialog.setup.title': 'Setup',
      'vehicle.dialog.return.title': 'Return',
      'vehicle.dialog.rent.title': 'Rent a vehicle',
      'vehicle.dialog.rent.content':
        'Do you rent the vehicle below and start the trip?',
      'vehicle.dailog.rent.error': 'Error failed in rent the vehicle',
      'vehicle.dialog.setup.submit.button': 'Submit',
      'vehicle.dialog.noStations.pre':
        'No stations registered yet. Please register a new one on ',
      'vehicle.dialog.noStations.stationPage': 'station page',
      'vehicle.dialog.noStations.post': '.',
      'select.station.dailog.allocation.error':
        'Error while allocating the vehicle',
      'select.station.dailog.return.error': 'Error while returning the vehicle',
      'dialog.serviceStatusNotLatest.error':
        'Because the service status is not latest, the system failed in the flow. Other users possibly executed it already. Please reload the window.',
      'vehicle.station.filter.select': 'Select a station',
      'vehicle.station.filter.nofilter': 'No Select',
      // trip
      'trip.nofilter': 'No Select',
      'trip.vehicle.id': 'Vehicle ID',
      'trip.vehicle.name': 'Vehicle Name',
      'trip.distance': 'Distance(m)',
      'trip.duration': 'Duration',
      'trip.duration.over24h': 'Over 24h',
      'trip.start.station': 'Start station',
      'trip.start.time': 'Start Time',
      'trip.end.station': 'End station',
      'trip.end.time': 'End Time',
      'trip.notReturned': 'Not returned',
      'trip.path.message.unavailable':
        'The trip was too short so the path is unavailable',
      // help
      'help.title': 'Need Help?',
      'help.contact': 'Contact us',
      'help.phoneNumber.caption': '(For corporations / weekday 9:00~18:00)',
      'help.contact.example': '...for example',
      'help.contact.example.escaltion': 'Escalation of troubles',
      'help.contact.example.repair': 'Repair request',
      'help.document': 'Documents',
      'help.document.vehicle': 'Vehicle manual',
      'help.document.kiosk': 'How to use KIOSK'
    }
  }
};

i18n
  .use(languagedetector)
  .use(initReactI18next)
  .init(
    {
      resources,
      ns: ['translation'],
      defaultNS: 'translation',
      supportedLngs: ['ja', 'en'],
      fallbackLng: 'ja',
      interpolation: {
        escapeValue: false
      }
    },
    (error) => console.log('i18n error:', error)
  );

export default i18n;
