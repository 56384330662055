import { FC } from 'react';
import { Marker } from '@react-google-maps/api';

import { Vehicle } from 'src/data/vehicle';
import commonVehicleIcon from 'src/assets/images/vehicle-icon.svg';
import vehicleFModelIcon from 'src/assets/images/vehicle-model-f-icon.svg';
import vehicleC2ModelIcon from 'src/assets/images/vehicle-model-c2-icon.svg';
import vehicleSModelIcon from 'src/assets/images/vehicle-model-s-icon.svg';

type Props = {
  vehicle: Vehicle;
  onUnmountMarkerHandler?: (
    marker: google.maps.Marker,
    vehicle: Vehicle
  ) => void;
  onClickMarkerHandler?: (
    e: google.maps.MapMouseEvent,
    vehicle: Vehicle
  ) => void;
  onLoadMarkerHandler?: (marker: google.maps.Marker, vehicle: Vehicle) => void;
};

export enum VehicleModel {
  C2 = 'C2',
  F = 'F',
  S = 'S',
  OTHER = 'OTHER'
}

const VEHICLE_ICONS_MAP: Record<VehicleModel, string> = {
  C2: vehicleC2ModelIcon,
  F: vehicleFModelIcon,
  S: vehicleSModelIcon,
  OTHER: commonVehicleIcon
};

const VehicleMarker: FC<Props> = ({
  vehicle,
  onUnmountMarkerHandler,
  onClickMarkerHandler,
  onLoadMarkerHandler
}) => {
  const vehicleIcon =
    VEHICLE_ICONS_MAP[vehicle.model as VehicleModel] ?? VEHICLE_ICONS_MAP.OTHER;

  const icon = {
    url: vehicleIcon,
    scaledSize: new google.maps.Size(40, 56)
  };

  return (
    <>
      {vehicle.position && vehicle.locationSource === 'gnss' && (
        <Marker
          key={vehicle.name || vehicle.id}
          position={vehicle.position}
          icon={icon}
          title={vehicle.name}
          onUnmount={(marker) =>
            onUnmountMarkerHandler && onUnmountMarkerHandler(marker, vehicle)
          }
          onClick={(event) =>
            onClickMarkerHandler && onClickMarkerHandler(event, vehicle)
          }
          onLoad={(marker) =>
            onLoadMarkerHandler && onLoadMarkerHandler(marker, vehicle)
          }
        />
      )}
    </>
  );
};

export default VehicleMarker;
