import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Switch,
  Typography
} from '@mui/material';
import { SvgIcon } from '@mui/material';
import { ReactComponent as editIcon } from '../../assets/images/edit-icon.svg';
import { useFacility } from '../../contexts/facility';
import { UpdateFacilityDialog, SetupGeofenceDialog } from './FacilityDialog';

export function FacilityDetail() {
  const { t } = useTranslation();
  const { isLoading, facility, update } = useFacility();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenGeofence, setIsOpenGeofence] = useState(false);

  const onClickEdit = () => {
    setIsOpen(true);
  };

  const onChangeSwitch = async () => {
    if (!facility) {
      return;
    } else if (facility.geofence === undefined) {
      setIsOpenGeofence(true);
    } else {
      await update(facility.id, { name: facility.name, geofence: null });
    }
  };

  return (
    <Paper elevation={1} className={'facility-details-container'}>
      <Grid container direction="column">
        {isLoading ? (
          <LinearProgress color="secondary" />
        ) : !facility ? (
          <></>
        ) : (
          <Grid
            container
            item
            direction="column"
            className={'facility-details'}
          >
            <Grid item className={'facility-header-area'}>
              <Typography
                className={'facility-name-in-detailcard'}
                component="div"
              >
                {facility.name}
              </Typography>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              <Box className={'facility-address-area'}>
                <Box className={'facility-address-header'}>
                  <Typography className="facility-sub-header">
                    {t('facility.address')}
                  </Typography>
                  <SvgIcon
                    onClick={onClickEdit}
                    className={'facility-details-svg icon-align'}
                    component={editIcon}
                    inheritViewBox
                  />
                </Box>
                <Box>
                  <Box>
                    <Typography className={'facility-label'}>
                      {t('facility.address.countryOrRegion')}
                    </Typography>
                    <Typography className="facility-data-value">
                      {facility.address.countryOrRegion}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    className={'address-zip-code facility-details-line'}
                  >
                    <Box>
                      <Typography className={'facility-label'}>
                        {t('facility.address.zipCode')}
                      </Typography>
                      <Typography className="facility-data-value">
                        {facility.address.zipCode}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography className={'facility-label'}>
                        {t('facility.address.province')}
                      </Typography>
                      <Typography className="facility-data-value">
                        {facility.address.province}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography className={'facility-label'}>
                        {t('facility.address.city')}
                      </Typography>
                      <Typography className="facility-data-value">
                        {facility.address.city}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="facility-details-line">
                    <Typography className={'facility-label'}>
                      {t('facility.address.address1')}
                    </Typography>
                    <Typography className="facility-data-value">
                      {facility.address.address1}
                    </Typography>
                  </Box>
                  <Box className="facility-details-line">
                    <Typography className={'facility-label'}>
                      {t('facility.address.address2')}
                    </Typography>
                    <Typography className="facility-data-value">
                      {facility.address.address2 || t('global.notRegistered')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              <Box className={'facility-geofence-panel'}>
                <Typography className="facility-sub-header">
                  {t('facility.geofence')}
                </Typography>
                <Switch
                  size="small"
                  checked={facility.geofence !== undefined}
                  onChange={onChangeSwitch}
                />
              </Box>
              {typeof facility.geofence !== 'undefined' &&
                facility.geofence !== null && (
                  <Box className="facility-details-line">
                    <Box className="facility-geofence-area">
                      <Box className={'geofence-type'}>
                        <Typography className={'facility-label'}>
                          {t('facility.geofence.type')}
                        </Typography>
                        <Typography className="facility-data-value">
                          {t('facility.geofence.circular')}
                        </Typography>
                      </Box>
                      <Box className={'geofence-radius'}>
                        <Typography className={'facility-label'}>
                          {t('facility.geofence.circular.radius')}
                        </Typography>
                        <Typography className="facility-data-value">
                          {facility.geofence?.config.radius}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
            </Grid>
            {facility && (
              <UpdateFacilityDialog
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                focusedFacility={facility}
              />
            )}
            {facility && (
              <SetupGeofenceDialog
                isOpen={isOpenGeofence}
                setIsOpen={setIsOpenGeofence}
                facility={facility}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
