import { isBefore, intervalToDuration, Duration } from 'date-fns';

export interface IPosition {
  latitude: number;
  longitude: number;
}
export class Trip {
  vehicleID: string;
  vehicleName: string;
  start: {
    stationID: string;
    stationName: string;
    odometer: number;
    startTime: Date;
  };
  end?: {
    stationID: string;
    stationName: string;
    odometer: number;
    endTime: Date;
  };
  path: Array<{
    lat: number;
    lng: number;
  }>;
  distance?: number;
  duration?: Duration;
  constructor(
    vehicleID: string,
    vehicleName: string,
    start: {
      stationID: string;
      stationName: string;
      odometer: number;
      startTime: Date;
    },
    path: Array<IPosition>,
    end?: {
      stationID: string;
      stationName: string;
      odometer: number;
      endTime: Date;
    }
  ) {
    this.vehicleID = vehicleID;
    this.start = start;
    this.end = end;
    this.vehicleName = vehicleName;
    if (end) {
      if (end.odometer - start.odometer >= 0) {
        this.distance = Math.round((end.odometer - start.odometer) * 1000);
      }
      if (isBefore(start.startTime, end.endTime)) {
        this.duration = intervalToDuration({
          start: start.startTime,
          end: end.endTime
        });
      }
    }

    this.path = path.map((path: IPosition) => {
      return {
        lat: path.latitude,
        lng: path.longitude
      };
    });
  }
}
