import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStations } from '../../contexts/stations';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  Button,
  Box,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { SvgIcon } from '@mui/material';
import Refresh from '@mui/icons-material/Refresh';
import { ReactComponent as editIcon } from '../../assets/images/edit-icon.svg';
import { ReactComponent as deleteIcon } from '../../assets/images/delete-icon.svg';
import { UpdateStationDialog } from '../organisms/StationDialog';
import {
  useOperationAlertDialog,
  DialogType
} from '../organisms/OperationAlertDialog';
interface StationDetailProps {
  toggleClick: () => void;
}
export function StationDetail(props: StationDetailProps) {
  const { toggleClick } = props;
  const { t } = useTranslation();
  const { focused, discard, fetchVehicles, isLoadingVehicles } = useStations();
  const { open } = useOperationAlertDialog();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClickEdit = () => {
    setIsOpen(true);
  };

  const onClickDiscard = () => {
    if (!focused) return;
    const onAgree = async () => {
      try {
        await discard(focused.station.id);
        toggleClick();
      } catch (error) {}
    };
    open(
      t('station.dialog.discard.title'),
      t('station.dialog.discard.description'),
      () => onAgree,
      [focused?.station.name],
      DialogType.DISCARD
    );
  };

  const onClickRefresh = () => {
    if (!focused) return;
    fetchVehicles(focused.station);
  };

  if (!focused) {
    return <></>;
  }
  const onClickCloseButton = () => {
    toggleClick();
  };
  return (
    <Paper elevation={1} className={'station-container'}>
      <Grid container direction="column">
        <Grid
          container
          item
          direction="column"
          className="station-details-container"
        >
          <Grid item>
            <Button
              onClick={onClickCloseButton}
              className={'station-details-backbutton'}
              startIcon={<ArrowBackIosNewIcon />}
            >
              {t('station.details.backButton')}
            </Button>
          </Grid>
          <Grid container item className={'station-details-card'}>
            <Grid container item justifyContent={'space-between'}>
              <Typography
                className={'station-name-in-detailcard'}
                component="div"
              >
                {focused.station.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={'station-details-capacity-text'}
                component="div"
              >
                {t('station.capacity')}
                <Typography className={'station-detail-capacity-value'}>
                  {`${focused.station.capacity}`}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Box className="station-details-icon-container">
              <SvgIcon
                onClick={onClickEdit}
                className={'station-details-svg'}
                component={editIcon}
                inheritViewBox
              />
              <SvgIcon
                onClick={onClickDiscard}
                className={'station-details-svg'}
                component={deleteIcon}
                inheritViewBox
              />
            </Box>
          </Grid>
          <Grid item>
            <Divider className={'station-details-divider'} />
          </Grid>
          <Grid item>
            <Typography
              className={'station-details-description-text'}
              component={'div'}
            >
              {t('station.description')}
            </Typography>
            <Typography className={'station-details-description-value'}>
              {focused.station.description}
            </Typography>
          </Grid>
          <Grid item>
            <Divider className={'station-details-divider'} />
          </Grid>
          <Grid container item direction="column">
            <Grid item className={'station-vehicle-list-header'}>
              <Typography
                className={'station-vehicle-list-title'}
                component={'div'}
              >
                {t('station.vehicles')}
              </Typography>
              <IconButton className={'station-vehicle-referesh-button'}>
                <Refresh onClick={onClickRefresh} />
              </IconButton>
            </Grid>
            <Grid item>
              <Box>
                {isLoadingVehicles ? (
                  <LinearProgress color="secondary" />
                ) : (
                  <>
                    <TableContainer
                      className={'station-vehicle-table-container'}
                    >
                      <Table size="small" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              className={'station-vehicle-table-header'}
                            >
                              {t('vehicle.id')}
                            </TableCell>
                            <TableCell
                              className={'station-vehicle-table-header'}
                            >
                              {t('vehicle.name')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {focused.vehicles?.map((vehicle) => (
                            <TableRow key={vehicle.id}>
                              <TableCell
                                className={'station-vehicle-table-cell'}
                              >
                                <Link to={`/vehicles/${vehicle.id}`}>
                                  {vehicle.id}
                                </Link>
                              </TableCell>
                              <TableCell
                                className={'station-vehicle-table-cell'}
                              >
                                {vehicle.name}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {focused && (
        <UpdateStationDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          focusedStation={focused.station}
        />
      )}
    </Paper>
  );
}
