import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { SnackbarProvider } from 'notistack';
import { NotificationReciever } from '../contexts/notification';
import { LoadScript } from '@react-google-maps/api';

import axios, { AxiosError, AxiosRequestHeaders } from 'axios';

import {
  LoadingPage,
  FacilityPage,
  StationsPage,
  VehiclesPage,
  HistoryPage,
  HelpPage,
  VehiclePage
} from './pages';

import { mapOptions } from '../assets/mapOptions';

import { ErrorDialog } from './organisms/ErrorDialog';

export function Router() {
  const {
    user,
    isAuthenticated,
    error,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently
  } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const accessToken = await getAccessTokenSilently();

        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${accessToken}`;

        axios.interceptors.response.use(
          (response) => {
            return response;
          },
          async (error: AxiosError) => {
            const { config, response } = error;
            if (response && response.status === 401) {
              if (config.params && config.params['retry']) {
                return Promise.reject(error);
              }
              const accessToken = await getAccessTokenSilently();

              axios.defaults.headers.common[
                'Authorization'
              ] = `Bearer ${accessToken}`;

              config.params = { ...config.params, retry: true };
              config.headers = {
                ...config.headers,
                Authorization: `Bearer ${accessToken}`
              };

              return axios.request(config);
            }
          }
        );
      })();
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  if (
    isAuthenticated &&
    !user?.['https://whill-share.com/md/app_metadata'].facility_id
  ) {
    return (
      <ErrorDialog
        title="Error: Facility is not configured for your account"
        message="Facility is not configured for your account. Please contact WHILL to fix this issue. Sorry for the inconvenience."
      />
    );
  }

  if (error) {
    return <ErrorDialog title={error.name} message={error.message} />;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!isAuthenticated) {
    loginWithRedirect({
      appState: { returnTo: window.location.pathname + window.location.search }
    });
    return <LoadingPage />;
  }

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY!}>
      <BrowserRouter>
        <SnackbarProvider autoHideDuration={36000000}>
          <NotificationReciever>
            <Routes>
              <Route path="/facility" element={<FacilityPage />} />
              <Route path="/stations" element={<StationsPage />} />
              {/* <Route path="/vehicles" element={<VehiclesPage />} /> */}
              <Route path="/history" element={<HistoryPage />} />
              {/* <Route path="/help" element={<HelpPage />} /> */}
              <Route path="/vehicles/:id" element={<VehiclePage />} />
              <Route path="/" element={<VehiclesPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </NotificationReciever>
        </SnackbarProvider>
      </BrowserRouter>
    </LoadScript>
  );
}
