import { Station } from '../../data/station';
import { Vehicle } from '../../data/vehicle';

export interface StationsState {
  isLoading: boolean;
  isLoadingVehicles: boolean;
  stations?: Array<Station>;
  focused?: {
    station: Station;
    vehicles?: Array<Vehicle>;
  };
  error?: Error;
}

export const initailStationsState: StationsState = {
  isLoading: false,
  isLoadingVehicles: false
};
