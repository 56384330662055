import { Vehicle, VehicleCommand } from '../../data/vehicle';
import { Station } from '../../data/station';
export interface VehicleState {
  isLoading: boolean;
  vehicle?: Vehicle;
  commandHistory?: Array<VehicleCommand>;
  error?: Error;
  stations?: Array<Station>;
}

export const initailVehicleState: VehicleState = {
  isLoading: false
};
