import axios from 'axios';

export const rent = async (
  vehicleId: string,
  stationId: string
): Promise<void> => {
  try {
    await axios.post(`/vehicles/${vehicleId}/trips`, {
      stationID: stationId,
      type: 'start',
      odometer: 0
    });
  } catch (error: any) {
    throw new Error(error);
  }
};
