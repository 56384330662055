import { createContext, useContext } from 'react';

import { VehicleState, initailVehicleState } from './vehicle-state';
import * as rest from '../../lib/api/rest';
import { APIType } from './vehicle-provider';

export interface IVehicleContext extends VehicleState {
  command: (
    id: string,
    props: rest.vehicle.command.SendCommandProps
  ) => Promise<void>;
  update: (id: string, props: rest.vehicle.UpdateVehicleProps) => Promise<void>;
  fetch: (apiType?: APIType) => Promise<void>;
  allocateVehicleToStation: (vehicleId: string, stationId: string) => any;
  rentVehicle: (vehicleId: string, stationId: string) => any;
  returnVehicle: (vehicleId: string, stationId: string) => any;
}

const defaultValue: IVehicleContext = {
  ...initailVehicleState,
  command: async (): Promise<void> => {},
  update: async (): Promise<void> => {},
  fetch: async (): Promise<void> => {},
  allocateVehicleToStation: async (): Promise<void> => {},
  rentVehicle: async (): Promise<void> => {},
  returnVehicle: async (): Promise<void> => {}
};

export const VehicleContext = createContext<IVehicleContext>(defaultValue);

export const useVehicle = (): IVehicleContext => useContext(VehicleContext);
