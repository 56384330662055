import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useFacility } from '../../../contexts/facility';
import { useStations } from '../../../contexts/stations';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography,
  Divider
} from '@mui/material';
import { GoogleMap, Marker } from '@react-google-maps/api';

import { mapOptions } from '../../../assets/mapOptions';

interface CreateStationDialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function CreateStationDialog(props: CreateStationDialogProps) {
  const { t } = useTranslation();
  const [markerPosition, setMarkerPosition] = useState<
    google.maps.LatLng | null | undefined
  >();
  const [name, setName] = useState<string>();
  const [capacity, setCapacity] = useState<number>(1);
  const [description, setDescription] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const { create } = useStations();
  const { isLoading, facility } = useFacility();

  const nameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const capacityChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCapacity(parseInt(event.target.value, 10));
  };

  const descriptionChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  const onLoadMarkerHandler = (marker: google.maps.Marker) => {
    setMarkerPosition(marker.getPosition());
  };

  const onDragEndMarkerHandler = (event: google.maps.MapMouseEvent) => {
    setMarkerPosition(event.latLng);
  };

  const onClickCreate = () => {
    if (name && markerPosition && capacity) {
      setErrorMessage(undefined);
      create({
        id: name,
        name,
        position: { lat: markerPosition.lat(), lng: markerPosition.lng() },
        capacity,
        description
      });
      setMarkerPosition(undefined);
      setName(undefined);
      setCapacity(1);
      setDescription(undefined);
      props.setIsOpen(false);
    } else {
      setErrorMessage(t('form.message.unfilled'));
    }
  };

  const onClickCancel = () => {
    setMarkerPosition(undefined);
    setName(undefined);
    setCapacity(1);
    setDescription(undefined);
    setErrorMessage(undefined);
    props.setIsOpen(false);
  };

  return (
    <Dialog
      open={props.isOpen}
      PaperProps={{
        className: 'station-create-dialog-container'
      }}
    >
      {isLoading || !facility ? (
        <LinearProgress />
      ) : (
        <>
          <DialogTitle className={'station-create-title'} component={'div'}>
            {t('station.dialog.create.title')}
          </DialogTitle>
          <Divider className={'station-create-divider'} />
          <DialogContent className={'station-create-dialog-content'}>
            <TextField
              className={'station-create-name-input'}
              required
              autoFocus
              label={t('station.name')}
              fullWidth
              margin="normal"
              onChange={nameChangeHandler}
              InputLabelProps={{ shrink: true, sx: { fontWeight: 400 } }}
            />
            <Box sx={{ marginY: '0.5rem' }}>
              <Typography
                variant="subtitle2"
                color="secondary"
                sx={{ fontSize: '0.75rem' }}
              >
                {t('station.geoLocation') + ' *'}
              </Typography>
              <Box
                sx={{
                  border: '1px solid',
                  borderColor: '#d7d7d7',
                  borderRadius: '5px'
                }}
              >
                <GoogleMap
                  zoom={16}
                  mapContainerStyle={{ width: 'inherit', height: '300px' }}
                  center={markerPosition || facility.position}
                  clickableIcons={false}
                  options={mapOptions}
                >
                  <Marker
                    draggable
                    position={markerPosition || facility.position}
                    onLoad={(marker) => onLoadMarkerHandler(marker)}
                    onDragEnd={(event) => onDragEndMarkerHandler(event)}
                  />
                </GoogleMap>
              </Box>
            </Box>
            <TextField
              required
              label={t('station.capacity')}
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              margin="normal"
              defaultValue={capacity}
              onChange={capacityChangeHandler}
            />
            <TextField
              label={t('station.description')}
              fullWidth
              multiline
              rows={4}
              margin="normal"
              InputLabelProps={{ shrink: true }}
              onChange={descriptionChangeHandler}
            />
            {errorMessage && (
              <Typography color="error">{errorMessage}</Typography>
            )}
          </DialogContent>
          <DialogActions className={'station-create-action-container'}>
            <Button
              className={'station-dialog-create-cancel'}
              onClick={onClickCancel}
            >
              {t('button.cancel')}
            </Button>
            <Button
              variant="contained"
              className={'station-dialog-create-button'}
              onClick={onClickCreate}
            >
              {t('button.create')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
