import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useFacility } from '../../../contexts/facility';
import { useStations } from '../../../contexts/stations';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography,
  Divider
} from '@mui/material';
import { GoogleMap, Marker } from '@react-google-maps/api';

import { Station } from '../../../data/station';

import { mapOptions } from '../../../assets/mapOptions';

interface UpdateStationDialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  focusedStation: Station;
}

export function UpdateStationDialog(props: UpdateStationDialogProps) {
  const { t } = useTranslation();
  const [markerPosition, setMarkerPosition] = useState<
    google.maps.LatLng | null | undefined
  >(new google.maps.LatLng(props.focusedStation.position));
  const [name, setName] = useState<string>(props.focusedStation.name);
  const [capacity, setCapacity] = useState<number>(
    props.focusedStation.capacity
  );
  const [description, setDescription] = useState<string | undefined>(
    props.focusedStation.description
  );
  const [errorMessage, setErrorMessage] = useState<string>();

  const { update } = useStations();
  const { isLoading } = useFacility();

  useEffect(() => {
    setMarkerPosition(new google.maps.LatLng(props.focusedStation.position));
    setName(props.focusedStation.name);
    setCapacity(props.focusedStation.capacity);
    setDescription(props.focusedStation.description);
    setErrorMessage(undefined);
  }, [props.isOpen]);

  const nameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const capacityChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCapacity(parseInt(event.target.value, 10));
  };

  const descriptionChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  const onLoadMarkerHandler = (marker: google.maps.Marker) => {
    setMarkerPosition(marker.getPosition());
  };

  const onDragEndMarkerHandler = (event: google.maps.MapMouseEvent) => {
    setMarkerPosition(event.latLng);
  };

  const onClickUpdate = async () => {
    if (name && markerPosition && capacity) {
      setErrorMessage(undefined);
      await update(props.focusedStation.id, {
        name,
        position: { lat: markerPosition.lat(), lng: markerPosition.lng() },
        capacity,
        description
      });
      props.setIsOpen(false);
    } else {
      setErrorMessage(t('form.message.unfilled'));
    }
  };

  const onClickCancel = () => {
    props.setIsOpen(false);
  };

  return (
    <Dialog
      open={props.isOpen}
      PaperProps={{
        className: 'station-create-dialog-container'
      }}
    >
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          <DialogTitle className={'station-create-title'} component={'div'}>
            {t('station.dialog.update.title')}
          </DialogTitle>
          <Divider className={'station-create-divider'} />
          <DialogContent className={'station-create-dialog-content'}>
            <TextField
              className={'station-create-name-input'}
              required
              autoFocus
              label={t('station.name')}
              fullWidth
              margin="normal"
              defaultValue={name}
              InputLabelProps={{ shrink: true, sx: { fontWeight: 400 } }}
              onChange={nameChangeHandler}
            />
            <Box sx={{ marginY: '0.5rem' }}>
              <Typography
                variant="subtitle2"
                color="secondary"
                sx={{ fontSize: '0.75rem' }}
              >
                {t('station.geoLocation') + ' *'}
              </Typography>
              <Box
                sx={{
                  border: '1px solid',
                  borderColor: '#d7d7d7',
                  borderRadius: '5px'
                }}
              >
                <GoogleMap
                  zoom={17}
                  mapContainerStyle={{ width: 'inherit', height: '300px' }}
                  center={markerPosition || props.focusedStation.position}
                  clickableIcons={false}
                  options={mapOptions}
                >
                  <Marker
                    draggable
                    position={markerPosition || props.focusedStation.position}
                    onLoad={(marker) => onLoadMarkerHandler(marker)}
                    onDragEnd={(event) => onDragEndMarkerHandler(event)}
                  />
                </GoogleMap>
              </Box>
            </Box>
            <TextField
              required
              label={t('station.capacity')}
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              margin="normal"
              defaultValue={capacity}
              onChange={capacityChangeHandler}
            />
            <TextField
              label={t('station.description')}
              fullWidth
              multiline
              rows={4}
              margin="normal"
              defaultValue={description}
              InputLabelProps={{ shrink: true }}
              onChange={descriptionChangeHandler}
            />
            {<Typography color="error">{errorMessage}</Typography>}
          </DialogContent>
          <DialogActions className={'station-create-action-container'}>
            <Button
              className={'station-dialog-create-cancel'}
              onClick={onClickCancel}
            >
              {t('button.cancel')}
            </Button>
            <Button
              className={'station-dialog-create-button'}
              variant="contained"
              onClick={onClickUpdate}
            >
              {t('button.edit')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
