import React, { ReactNode, useReducer } from 'react';

import { Trip } from '../../data/trip';
import { initailTripsState, TripsState } from './trips-state';

import * as rest from '../../lib/api/rest';
import { TripsContext } from './trips-context';

type Action =
  | {
      type: 'FETCH';
    }
  | {
      type: 'FETCHED';
      payload: {
        trips?: Array<Trip>;
        nextCursor?: string;
        selectedVehicleID?: string;
      };
    }
  | {
      type: 'FETCH_MORE';
    }
  | {
      type: 'FETCHED_MORE';
      payload: {
        trips?: Array<Trip>;
        nextCursor?: string;
      };
    }
  | {
      type: 'FOCUS';
      payload: {
        focusedTrip: Trip;
      };
    }
  | {
      type: 'ERROR';
      payload: {
        error: Error;
      };
    };

function reducer(state: TripsState, action: Action): TripsState {
  switch (action.type) {
    case 'FETCH':
      return {
        ...state,
        isLoading: true,
        nextCursor: undefined
      };
    case 'FETCHED':
      return {
        ...state,
        isLoading: false,
        trips: action.payload.trips,
        nextCursor: action.payload.nextCursor,
        selectedVehicleID: action.payload.selectedVehicleID
      };
    case 'FETCH_MORE':
      return {
        ...state,
        isLoading: true
      };
    case 'FETCHED_MORE':
      return {
        ...state,
        isLoading: false,
        trips: state.trips
          ? state.trips.concat(action.payload.trips || [])
          : action.payload.trips,
        nextCursor: action.payload.nextCursor
      };
    case 'FOCUS':
      return {
        ...state,
        focused: { trip: action.payload.focusedTrip }
      };
    case 'ERROR':
      return {
        ...state,
        error: action.payload.error
      };
  }
}

interface TripsProviderProps {
  children: ReactNode;
}

export function TripsProvider(props: TripsProviderProps) {
  const [state, dispatch] = useReducer(reducer, initailTripsState);

  const fetch = async (
    id?: string,
    params?: rest.vehicle.trip.ListRequestParams
  ): Promise<void> => {
    dispatch({ type: 'FETCH' });
    try {
      const { trips, metadata } = await rest.vehicle.trip.list(id, params);
      dispatch({
        type: 'FETCHED',
        payload: {
          trips,
          nextCursor: metadata.nextCursor,
          selectedVehicleID: id
        }
      });
    } catch (error) {
      dispatch({ type: 'ERROR', payload: { error: error as Error } });
    }
  };

  const fetchMore = async (
    params?: rest.vehicle.trip.ListRequestParams
  ): Promise<void> => {
    dispatch({ type: 'FETCH_MORE' });
    try {
      const { trips, metadata } = await rest.vehicle.trip.list(
        state.selectedVehicleID,
        { ...params, cursor: state.nextCursor }
      );
      dispatch({
        type: 'FETCHED_MORE',
        payload: { trips, nextCursor: metadata.nextCursor }
      });
    } catch (error) {
      dispatch({ type: 'ERROR', payload: { error: error as Error } });
    }
  };

  const focus = (focusedTrip: Trip) => {
    dispatch({ type: 'FOCUS', payload: { focusedTrip } });
  };

  return (
    <TripsContext.Provider value={{ ...state, fetch, fetchMore, focus }}>
      {props.children}
    </TripsContext.Provider>
  );
}
