import { createContext, useContext } from 'react';

import { Station } from '../../data/station';
import * as rest from '../../lib/api/rest';

import { StationsState, initailStationsState } from './stations-state';

export interface IStationsContext extends StationsState {
  focus: (focusedStation?: Station) => Promise<void>;
  fetchVehicles: (station: Station) => Promise<void>;
  create: (
    props: rest.station.CreateStationProps
  ) => Promise<Station | undefined>;
  update: (
    id: string,
    props: rest.station.UpdateStationProps
  ) => Promise<Station | undefined>;
  discard: (id: string) => Promise<void>;
}

const defaultValue: IStationsContext = {
  ...initailStationsState,
  focus: async (): Promise<void> => {},
  fetchVehicles: async (): Promise<void> => {},
  create: async (): Promise<Station | undefined> => {
    return undefined;
  },
  update: async (): Promise<Station | undefined> => {
    return undefined;
  },
  discard: async (): Promise<void> => {}
};

export const StationsContext = createContext<IStationsContext>(defaultValue);

export const useStations = (): IStationsContext => useContext(StationsContext);
