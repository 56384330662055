import { Vehicle } from '../../data/vehicle';
import { Station } from '../../data/station';

export interface VehiclesState {
  isLoading: boolean;
  vehicles?: Array<Vehicle>;
  stations?: Array<Station>;
  error?: Error;
}

export const initailVehiclesState: VehiclesState = {
  isLoading: false
};
