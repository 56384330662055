import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { GoogleMap } from '@react-google-maps/api';

import { useVehicle } from 'src/contexts/vehicle';
import { Vehicle } from 'src/data/vehicle';
import VehicleMarker from 'src/components/molecules/VehicleMarker';

import { mapOptions } from '../../assets/mapOptions';

export function VehicleDetailMap() {
  const { t } = useTranslation();
  const { vehicle } = useVehicle();

  const [loadedMarker, setLoadedMarker] = useState<
    google.maps.Marker | undefined
  >();
  const [loadedWindow, setLoadedWindow] = useState<
    google.maps.InfoWindow | undefined
  >();
  const [map, setMap] = useState<google.maps.Map>();

  const onClickMarkerHandler = (event: any, vehicle: Vehicle): void => {
    if (loadedWindow) {
      return;
    }
    const window = new google.maps.InfoWindow({
      content: `<div><div class="vehicle-map-info-line" ></div><div class="vehicle-map-info-window">${vehicle.name}</div></div>`
    });
    setLoadedWindow(window);
    window.open(map, loadedMarker);
    window.addListener('closeclick', () => {
      setLoadedWindow(undefined);
    });
  };

  const onLoadMarkerHandler = (
    marker: google.maps.Marker,
    vehicle: Vehicle
  ): void => {
    setLoadedMarker(marker);
    const window = new google.maps.InfoWindow({
      content: `<div><div class="vehicle-map-info-line" ></div><div class="vehicle-map-info-window">${vehicle.name}</div></div>`,
      disableAutoPan: true
    });
    setLoadedWindow(window);
    window.open(map, marker);
    window.addListener('closeclick', () => {
      setLoadedWindow(undefined);
    });
  };

  const onLoadMapHandler = (loadedMap: google.maps.Map) => {
    setMap(loadedMap);
  };

  return (
    <Grid xs={12}>
      <Box>
        {vehicle && vehicle.position && vehicle.locationSource === 'gnss' ? (
          <GoogleMap
            mapContainerClassName={'vehicle-map-container'}
            zoom={18}
            center={vehicle.position}
            onLoad={(loadedMap) => {
              onLoadMapHandler(loadedMap);
            }}
            options={mapOptions}
          >
            <VehicleMarker
              vehicle={vehicle}
              onClickMarkerHandler={onClickMarkerHandler}
              onLoadMarkerHandler={onLoadMarkerHandler}
            />
          </GoogleMap>
        ) : !vehicle ? (
          <></>
        ) : (
          <Box className={'vehicle-map-error-container'}>
            <Typography variant="h5">{t('vehicle.position.noData')}</Typography>
          </Box>
        )}
      </Box>
    </Grid>
  );
}
