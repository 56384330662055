import { gql } from '@apollo/client';

export interface SubscriptionResponse {
  onPublishVehicleStatus: {
    batteryLevel: number;
    chargingStatus: string;
    createdTime: string;
    currentStationID: string;
    facilityID: string;
    geofenceStatus: string;
    geoLocationLatitude: number;
    geoLocationLongitude: number;
    locationSource: string;
    lockStatus: string;
    movingStatus: string;
    serviceStatus: string;
    serviceStartTime?: string;
    vehicleID: string;
  };
}

export const onPublishVehicleStatus = gql`
  subscription OnPublishVehicleStatus($facilityID: String!) {
    onPublishVehicleStatus(facilityID: $facilityID) {
      batteryLevel
      chargingStatus
      createdTime
      currentStationID
      facilityID
      geoLocationLatitude
      geoLocationLongitude
      locationSource
      geofenceStatus
      lockStatus
      # movingStatus
      serviceStatus
      serviceStartTime
      vehicleID
    }
  }
`;

export const onPublishVehicleStatusPerVehicle = gql`
  subscription OnPublishVehicleStatus(
    $facilityID: String!
    $vehicleID: String
  ) {
    onPublishVehicleStatus(facilityID: $facilityID, vehicleID: $vehicleID) {
      batteryLevel
      chargingStatus
      createdTime
      currentStationID
      facilityID
      geoLocationLatitude
      geoLocationLongitude
      locationSource
      geofenceStatus
      lockStatus
      # movingStatus
      serviceStatus
      serviceStartTime
      vehicleID
    }
  }
`;
