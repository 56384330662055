import React, { ReactNode, useState } from 'react';

import {
  OperationAlertDialogContext,
  DialogType
} from './OperationAlertDialogContext';

interface OperationAlertDialogProviderOptions {
  children: ReactNode;
}

export function OperationAlertDialogProvider(
  options: OperationAlertDialogProviderOptions
) {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [targets, setTargets] = useState<Array<string>>();
  const [onAgree, setOnAgree] = useState<() => () => void>();
  const [dialogType, setDialogType] = useState<DialogType>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const open = (
    title?: string,
    description?: string,
    onAgree?: () => () => void,
    targets?: Array<string>,
    type?: DialogType
  ) => {
    setTitle(title);
    setDescription(description);
    setTargets(targets);
    setDialogType(type);
    setOnAgree(onAgree);
    setIsOpen(true);
  };

  const agree = async () => {
    try {
      setErrorMessage('');
      onAgree && (await onAgree());
      setIsOpen(false);
      await setTimeout(() => {
        setTitle(undefined);
        setDescription(undefined);
        setTargets(undefined);
        onAgree && setOnAgree(undefined);
        setErrorMessage('');
        setLoading(false);
      }, 100);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  };

  const close = async () => {
    setIsOpen(false);
    await setTimeout(() => {
      setTitle(undefined);
      setDescription(undefined);
      setTargets(undefined);
      onAgree && setOnAgree(undefined);
      setErrorMessage('');
      setLoading(false);
    }, 100);
  };
  const showLoading = (loading: boolean) => {
    setLoading(loading);
  };

  return (
    <OperationAlertDialogContext.Provider
      value={{
        isOpen,
        title,
        description,
        targets,
        dialogType,
        errorMessage,
        loading,
        open,
        agree,
        close,
        showLoading
      }}
    >
      {options.children}
    </OperationAlertDialogContext.Provider>
  );
}
