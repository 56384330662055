import axios from 'axios';

import { Station } from '../../../../data/station';

export interface UpdateStationProps {
  name?: string;
  position?: {
    lat: number;
    lng: number;
  };
  description?: string;
  capacity?: number;
  registeredBy?: string;
}

type ResponseType = {
  id: string;
  name: string;
  geoLocation: {
    latitude: number;
    longitude: number;
  };
  description: string;
  capacity: number;
  facilityID: string;
  createdTime: string;
  createdBy: string;
  lastUpdateTime: string;
  updatedBy: string;
};

export const update = async (
  id: string,
  props: UpdateStationProps
): Promise<Station> => {
  try {
    const response = await axios.patch<ResponseType>(`/stations/${id}`, {
      id,
      name: props.name,
      geoLocation: props.position && {
        latitude: props.position.lat,
        longitude: props.position.lng
      },
      description: props.description,
      capacity: props.capacity,
      registeredBy: props.registeredBy
    });

    return new Station(
      response.data.id,
      response.data.name,
      {
        lat: response.data.geoLocation.latitude,
        lng: response.data.geoLocation.longitude
      },
      response.data.capacity,
      response.data.description,
      new Date(response.data.createdTime),
      response.data.createdBy,
      new Date(response.data.lastUpdateTime),
      response.data.updatedBy
    );
  } catch (error: any) {
    throw new Error(error.messaage);
  }
};
