import React from 'react';
import '../../css/history.css';
import { Grid } from '@mui/material';

import { Template } from '../templates/Template';

import { TripsProvider } from '../../contexts/trips';
import { TripsList } from '../organisms';

export function HistoryPage() {
  return (
    <Template>
      <TripsProvider>
        <Grid
          container
          style={{ height: 'calc(var(--vh, 1vh) * 100 - 72px - 2px)' }}
        >
          <TripsList />
        </Grid>
      </TripsProvider>
    </Template>
  );
}
