import { useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

import { useVehicles } from 'src/contexts/vehicles';
import { useFacility } from 'src/contexts/facility';
import { Vehicle } from 'src/data/vehicle';
import { Station } from 'src/data/station';

import { mapOptions } from '../../assets/mapOptions';

import VehicleMarker from 'src/components/molecules/VehicleMarker';

import stationIcon from 'src/assets/images/station-icon.svg';

export function VehiclesMap() {
  const { facility } = useFacility();
  const { vehicles, stations } = useVehicles();
  const [loadedMarkers, setLoadedMarkers] = useState<{
    [id: string]: google.maps.Marker;
  }>({});
  const [loadedWindows, setLoadedWindows] = useState<{
    [id: string]: google.maps.InfoWindow;
  }>({});
  const [loadedStationMarkers, setLoadedStationMarkers] = useState<{
    [id: string]: google.maps.Marker;
  }>({});
  const [loadedStationWindows, setLoadedStationWindows] = useState<{
    [id: string]: google.maps.InfoWindow;
  }>({});
  const [map, setMap] = useState<google.maps.Map>();

  const onLoadMapHandler = (loadedMap: google.maps.Map) => {
    setMap(loadedMap);
  };

  const unsetMarker = (id: string) => {
    const markers = Object.keys(loadedMarkers).reduce(
      (object: typeof loadedMarkers, key) => {
        if (key !== id) {
          object[key] = loadedMarkers[key];
        }
        return object;
      },
      {}
    );
    setLoadedMarkers(markers);
    unsetWindow(id);
  };

  const unsetWindow = (id: string) => {
    setLoadedWindows((prevState) =>
      Object.keys(prevState).reduce((object: typeof loadedWindows, key) => {
        if (key !== id) {
          object[key] = prevState[key];
        }
        return object;
      }, {})
    );
  };

  const onLoadMarkerHandler = (
    marker: google.maps.Marker,
    vehicle: Vehicle
  ): void => {
    setLoadedMarkers((prevState) => ({ [vehicle.id]: marker, ...prevState }));
    const window = new google.maps.InfoWindow({
      content: `<div><div class="vehicle-map-info-line" ></div><div class="vehicle-map-info-window">${vehicle.name}</div></div>`,
      disableAutoPan: true
    });
    setLoadedWindows((prevState) => ({ [vehicle.id]: window, ...prevState }));
    window.open(map, marker);
    window.addListener('closeclick', () => {
      unsetWindow(vehicle.id);
    });
  };

  const onClickMarkerHandler = (event: any, vehicle: Vehicle): void => {
    if (loadedWindows[vehicle.id]) {
      return;
    }
    const window = new google.maps.InfoWindow({
      content: `<div><div class="vehicle-map-info-line" ></div><div class="vehicle-map-info-window">${vehicle.name}</div></div>`
    });
    setLoadedWindows((prevState) => ({ [vehicle.id]: window, ...prevState }));
    window.open(map, loadedMarkers[vehicle.id]);
    window.addListener('closeclick', () => {
      unsetWindow(vehicle.id);
    });
  };

  const onUnmountMarkerHandler = (
    marker: google.maps.Marker,
    vehicle: Vehicle
  ) => {
    unsetMarker(vehicle.id);
  };

  const unsetStationWindow = (id: string) => {
    setLoadedStationWindows((prevState) =>
      Object.keys(prevState).reduce(
        (object: typeof loadedStationWindows, key) => {
          if (key !== id) {
            object[key] = prevState[key];
          }
          return object;
        },
        {}
      )
    );
  };

  const onLoadStationMarkerHandler = (
    marker: google.maps.Marker,
    station: Station
  ) => {
    setLoadedStationMarkers((prevState) => ({
      [station.id]: marker,
      ...prevState
    }));
    const window = new google.maps.InfoWindow({
      content: `<div><div class="station-map-info-line" ></div><div class="vehicle-map-info-window">${station.name}</div></div>`,
      disableAutoPan: true
    });
    setLoadedStationWindows((prevState) => ({
      [station.id]: window,
      ...prevState
    }));
    window.open(map, marker);
    window.addListener('closeclick', () => {
      unsetStationWindow(station.id);
    });
  };

  const onClickStationMarkerHandler = (event: any, station: Station) => {
    if (loadedStationWindows[station.id]) {
      return;
    }
    const window = new google.maps.InfoWindow({
      content: `<div><div class="station-map-info-line" ></div><div class="vehicle-map-info-window">${station.name}</div></div>`
    });
    setLoadedStationWindows((prevState) => ({
      [station.id]: window,
      ...prevState
    }));
    window.open(map, loadedStationMarkers[station.id]);
    window.addListener('closeclick', () => {
      unsetStationWindow(station.id);
    });
  };

  return (
    <GoogleMap
      zoom={18}
      mapContainerClassName={'vehicle-map-container'}
      center={facility?.position}
      options={mapOptions}
      onLoad={(loadedMap) => {
        onLoadMapHandler(loadedMap);
      }}
    >
      {vehicles &&
        vehicles.map((vehicle, index) => (
          <VehicleMarker
            key={index}
            vehicle={vehicle}
            onUnmountMarkerHandler={onUnmountMarkerHandler}
            onClickMarkerHandler={onClickMarkerHandler}
            onLoadMarkerHandler={onLoadMarkerHandler}
          />
        ))}
      {stations &&
        stations.map(
          (station) =>
            station.position && (
              <Marker
                key={station.id}
                title={station.name}
                position={station.position}
                icon={stationIcon}
                onClick={(event) => {
                  onClickStationMarkerHandler(event, station);
                }}
                onLoad={(marker) => {
                  onLoadStationMarkerHandler(marker, station);
                }}
              />
            )
        )}
    </GoogleMap>
  );
}
