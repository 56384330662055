export interface ICircularGeofence {
  type: string;
  config: {
    centerPoint: {
      lat: number;
      lng: number;
    };
    radius: number;
  };
}

// export interface IPolygonGeofence {
//   type: 'Polygon';
//   config: {
//     points: Array<{ lat: number; long: number; }>
//   }
// }

export class Facility {
  id: string;
  name: string;
  position: { lat: number; lng: number };
  address: {
    countryOrRegion: string;
    province: string;
    city: string;
    address1: string;
    address2?: string;
    zipCode: string;
  };
  timeZoneID: string;
  businessHours: {
    startTime: string;
    endTime: string;
  };
  createdTime: Date;
  createdBy: string;
  lastUpdatedTime: Date;
  updatedBy: string;
  description?: string;
  geofence?: ICircularGeofence;

  constructor(
    id: string,
    name: string,
    position: { lat: number; lng: number },
    address: {
      countryOrRegion: string;
      province: string;
      city: string;
      address1: string;
      address2?: string;
      zipCode: string;
    },
    timeZoneID: string,
    businessHours: {
      startTime: string;
      endTime: string;
    },
    createdTime: Date,
    createdBy: string,
    lastUpdatedTime: Date,
    updatedBy: string,
    geofence?: ICircularGeofence,
    description?: string
  ) {
    this.id = id;
    this.name = name;
    this.position = position;
    this.address = address;
    this.timeZoneID = timeZoneID;
    this.businessHours = businessHours;
    this.createdTime = createdTime;
    this.createdBy = createdBy;
    this.lastUpdatedTime = lastUpdatedTime;
    this.updatedBy = updatedBy;
    this.geofence = geofence;
    this.description = description;
  }
}
