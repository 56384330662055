import axios from 'axios';

export const setup = async (
  vehicleId: string,
  stationId: string
): Promise<void> => {
  try {
    await axios.post(`/vehicles/station-allocation`, {
      vehicles: [
        {
          id: vehicleId
        }
      ],
      stationID: stationId
    });
  } catch (error: any) {
    throw new Error(error);
  }
};
