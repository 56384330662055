import { Trip } from '../../data/trip';

export interface TripsState {
  isLoading: boolean;
  trips?: Array<Trip>;
  selectedVehicleID?: string;
  nextCursor?: string;
  focused?: { trip: Trip };
  error?: Error;
}

export const initailTripsState: TripsState = {
  isLoading: false
};
