import '../../css/vehicle.css';
import { FacilityProvider } from '../../contexts/facility';
import { VehiclesProvider } from '../../contexts/vehicles';
import { Template } from '../templates/Template';
import { VehiclesList, VehiclesMap } from '../organisms';
import PageLayout from '../organisms/PageLayout';
import { OperationAlertDialog } from '../organisms/OperationAlertDialog';

export function VehiclesPage() {
  return (
    <Template>
      <VehiclesProvider>
        <FacilityProvider>
          <PageLayout
            childrenBar={<VehiclesList />}
            childrenMap={<VehiclesMap />}
          />
          <OperationAlertDialog />
        </FacilityProvider>
      </VehiclesProvider>
    </Template>
  );
}
